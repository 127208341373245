// @vendors
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

// @store
import useOnboardingStore from '../../store/useOnboardingStore';
import { useSpinnerStore } from '@/store/useSpinnerStore';

// @components
import TermsAndConditionsPage from '../TermsAndConditionsPage';

interface SubscriptionTermsOnboardingPageProps {
  onNext: () => void;
}

const SubscriptionTermsOnboardingPage: React.FC<SubscriptionTermsOnboardingPageProps> = ({ onNext }) => {
  const { t } = useTranslation();
  const { updateReferralEmail } = useOnboardingStore();
  const [referalEmail, setReferalEmail] = useState<string>('');
  //const [isSubmitting, setIsSubmitting] = useState(false);
  //const [acceptedTerms, setAcceptedTerms] = useState(false);
  //const [isSubmittingEmail, setIsSubmittingEmail] = useState(false);
  const { openSpinner } = useSpinnerStore();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReferalEmail(e.target.value);
  };

  const handleReferralEmailSubmit = async (e?: React.FormEvent) => {
    if (e) e.preventDefault(); // Prevent form submission
    if (!referalEmail) return;
    openSpinner(true);
    try {
      // Add API call to submit referral email
      await updateReferralEmail(referalEmail);
      setReferalEmail(''); // Clear email after successful submission
      openSpinner(false);
      toast.success("The referral's email was sent successfully");
    } catch (error) {
      openSpinner(false);
      toast.error('Error submitting referral email');
    } finally {
      openSpinner(false);
    }
  };

  return (
    <div className="min-h-screen bg-white p-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Subscription Column */}
        <div className="flex flex-col">
          <h2 className="text-2xl font-bold mb-6">{t('onboarding.subscription.title')}</h2>
          <div className="flex flex-col space-y-6">
            <p className="text-lg text-gray-700">{t('onboarding.subscription.introText')}</p>
            <div>
              <label className="text-lg font-semibold block mb-2">{t('onboarding.subscription.referredQuestion')}</label>
              <div className="flex flex-col md:flex-row gap-5">
                <input
                  type="email"
                  placeholder={t('onboarding.subscription.emailPlaceholder')}
                  value={referalEmail}
                  onChange={handleEmailChange}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleReferralEmailSubmit();
                    }
                  }}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-red-500"
                />
                <button
                  type='button'
                  onClick={handleReferralEmailSubmit}
                  className={`w-full md:w-[120px] bg-red-500 text-white px-8 py-3 rounded-full ${!referalEmail ? 'opacity-50' : 'hover:bg-red-600'} transition duration-300`}
                  disabled={referalEmail ? false : true}
                >
                  {t('onboarding.terms.send')}
                </button>
              </div>
            </div>
            <p className="text-sm text-gray-600">{t('onboarding.subscription.referralBonus')}</p>
          </div>
        </div>

        {/* Terms Column */}
        <div className="flex flex-col">
          <TermsAndConditionsPage onNext={onNext} />
        </div>
      </div>

      {/* Submit Button */}
      <div className="flex justify-center mt-8">
        <button
          disabled={true}
          //disabled={isLoading || isSubmitting || !acceptedTerms}
          className="bg-red-500 text-white px-8 py-3 rounded-full hover:bg-red-600 transition duration-300 disabled:bg-gray-400"
        >
          {t('onboarding.categorySelection.continue')}
        </button>
      </div>
    </div>
  );
};

export default SubscriptionTermsOnboardingPage;
