import React, { useState, useRef } from "react";
import ServiceCard from "./ServiceCard";
import DetailService from "./DetailService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NoServiceMessage } from "./NoServiceMessageCard";
import { useEffect } from "react";
import useServiceRequestStore from "@/store/useServiceRequestStore";
import { ServiceRequest } from "@/types/request.types";
import ServiceRequestDetail from "@/pages/ServiceRequestDetail";
import useProposalStore from "@/store/useProposalStore";

export const CompletedServices: React.FC = () => {
  const [selectedService, setSelectedService] = useState<ServiceRequest | null>(null);
  const { getCompletedServices, completedServices, isLoading } = useServiceRequestStore();
  const { getRequestsByUser, response } = useServiceRequestStore();
  const { data, fetchProposalsByRequest } = useProposalStore();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getCompletedServices();
  }, [getCompletedServices]);

  useEffect(() => {
    getRequestsByUser();
  }, [getRequestsByUser]);

  useEffect(() => {
    if (selectedService?.serviceRequestId) {
      fetchProposalsByRequest(selectedService.serviceRequestId);
    }
  }, [response?.items, fetchProposalsByRequest]);

  /* const completedServices = response?.items?.filter(
    (service: ServiceRequest) => String(service.serviceRequestStatus) === "2"
  ) || []; */

  const handleScroll = (direction: 'left' | 'right') => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      scrollContainerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const handleServiceClick = (servicio: ServiceRequest) => {
    setSelectedService(servicio);
  };

  if (selectedService) {
    return (
      <DetailService
        servicio={selectedService}
        onBack={() => setSelectedService(null)}
      />
    );
  }

    /* if (selectedService) {
      return (
        <ServiceRequestDetail
          service={selectedService}
        />
      );
    } */

  return (
    <section>
      <h2 className="text-lg font-semibold mb-4">{t("Servicios Completados")}</h2>
      <div className="rounded-lg p-5">
        {completedServices.length === 0 ? (
          <NoServiceMessage
            title="Actualmente no tienes servicios disfrutados"
            messageOne="Aquí aparecerán lo servicios que hayas disfrutado dentro de XERVIX"
            buttonText="Solicitar Servicio"
            onClick={() => navigate("/categories")}
          />
        ) : (
          <div className="relative">
            <button 
              onClick={() => handleScroll('left')}
              className="absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-white rounded-full p-2 shadow-md hover:bg-gray-100"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
              </svg>
            </button>
            
            <div
              ref={scrollContainerRef}
              className="flex gap-4 p-5 overflow-x-auto scrollbar-hide scroll-smooth px-8"
            >
              {completedServices.map((service: ServiceRequest) => (
                <div className="flex-none w-72" key={service.serviceRequestId}>
                  <ServiceCard
                    service={service}
                    onClick={() => handleServiceClick(service)}
                  />
                </div>
              ))}
            </div>

            <button
              onClick={() => handleScroll('right')}
              className="absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-white rounded-full p-2 shadow-md hover:bg-gray-100"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default CompletedServices;
