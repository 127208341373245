import DynamicForm from "../components/organisms/DynamicForm";
import { BaseLayout } from "../components/templates/BaseLayout";

const ServiceFormPage = () => {
  return (
      <div className="flex flex-col mt-6 px-6 lg:px-0">
        <h1 className="text-[16px] lg:text-[20px] font-semibold text-gray-900 mb-6 ">
          Ingresa la siguiente información
        </h1>
          <DynamicForm />
      </div>
  );
};

export default ServiceFormPage;
