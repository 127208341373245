export interface Proposal {
  proposalId: string;
  serviceRequestId: string;
  professionalId: string;
  proposalValue: string;
  daysToComplete: number;
  descriptionProposet: string;
  proposalStatus: string;
  createdAt: string;
  updatedAt: string;
  rating?: number;
}

export interface ProposalResponse {
  items: Proposal[];
  totalItems: number;
  pageNumber: number;
  pageSize: number;
}

export enum ProposalState {
  Approved = 0,
  Rejected = 1
}

export interface Customer {
    customerId: string;
    names: string;
    lastNames: string;
    email: string;
    phoneNumber: string;
    address: string;
    photoUrl: string;
    createdAt: string;
};

export interface ProposalDetail {
  proposalId: string;
  serviceRequestId: string;
  professionalId: string;
  proposalValue: number;
  daysToComplete: number;
  proposalStatus: string;
  descriptionProposet: string;
  createdAt: string;
  updatedAt: string;
  customer: Customer;
}