// @vendors
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

// @store
import useCategoryStore from '../../store/useCategoryStore';
import useOnboardingStore from '../../store/useOnboardingStore';
import { useSpinnerStore } from '@/store/useSpinnerStore';

// @types
import { CategoryHierarchy } from '../../types/category.types';

// @components
import CategoriesGridSelection from '../../components/organisms/onboarding/CategoriesGridSelection';
import CategorySearchInput from '../../components/molecules/CategorySearchInput';
//import Modal from '../../components/Modals/ModalErrorValidation';

interface CategoryOnboardingPageProps {
  onNext: (categoryId: string) => void;
}

const REQUIRED_CATEGORIES = 1;

const CategoryOnboardingPage: React.FC<CategoryOnboardingPageProps> = ({ onNext }) => {
  const { t } = useTranslation();
  const { categoryHierarchy, fetchCategoriesHierarchy, isLoading } = useCategoryStore();
  const { submitCategories } = useOnboardingStore();
  const [selectedCategories, setSelectedCategories] = useState<CategoryHierarchy[]>([]);
  const [filteredCategoryId, setFilteredCategoryId] = useState<string | null>(null);
  const { openSpinner } = useSpinnerStore();

  useEffect(() => {
    if (isLoading) openSpinner(true);
    else openSpinner(false);
  }, [isLoading, openSpinner])

  useEffect(() => {
    fetchCategoriesHierarchy();
  }, [fetchCategoriesHierarchy]);

  /**
   * Handles the selection of a category.
   * If the category is already selected, it will be removed from the selection.
   * If the category is not selected and the maximum number of required categories is reached, a modal will be shown.
   * @param category The category to be selected or deselected.
   */
  const handleCategorySelect = (category: CategoryHierarchy) => {
    let itemSelected = [category];
    const isSelected = selectedCategories.find(cat => cat.categoryId === category.categoryId);
    if (isSelected?.categoryId) itemSelected = [];
    setSelectedCategories(itemSelected);
  };

  /* const handleModalContinue = () => {
    setShowModal(false);
  }; */

  /**
   * Handles the submission of the selected categories.
   * If the number of selected categories is less than the required number, a modal will be shown.
   * Otherwise, it simulates an API call and proceeds to the next step.
   */
  const handleSubmit = async () => {
    openSpinner(true);
    try {
      const categoryIds = selectedCategories.map(cat => cat.categoryId);
      await submitCategories(categoryIds);
      toast.success(t('onboarding.categorySelection.submitSuccess'));
      onNext(selectedCategories[0]?.categoryId);
      openSpinner(false);
    } catch (error: any) {
      openSpinner(false);
      toast.error(error.message);
    }
  };

  const handleFilterCategories = (categoryId: string | null) => {
    setFilteredCategoryId(categoryId);
  };

  const filteredCategories = useMemo(() => {
    if (!filteredCategoryId) return categoryHierarchy;
    return categoryHierarchy?.filter(cat => cat.categoryId === filteredCategoryId) || [];
  }, [categoryHierarchy, filteredCategoryId]);

  return (
    <div className="p-8 dark:bg-dark-cBackground font-sans">

      {/* Welcome Title */}
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold text-cText dark:text-dark-cText">
          {t('onboarding.categorySelection.title')}
        </h2>
        <h3 className='mt-4 font-medium '>{t('onboarding.categorySelection.chooseCategory')}</h3>
      </div>

      {/* Search bar with autocomplete */}
      <CategorySearchInput
        onSelect={handleCategorySelect}
        selectedCategories={selectedCategories}
        onFilterCategories={handleFilterCategories}
      />

      {/* List of categories */}
      <div className="w-full mt-8">
        <CategoriesGridSelection
          onSelect={handleCategorySelect}
          categories={filteredCategories || []}
          selectedCategories={selectedCategories}
          loading={isLoading}
        />

        {/* Continue Button */}
        <div className="flex justify-center mt-8">
          <button
            onClick={handleSubmit}
            className="bg-red-500 text-white px-8 py-3 rounded-lg disabled:opacity-50"
            disabled={selectedCategories.length < REQUIRED_CATEGORIES || isLoading}
          >
            {isLoading ? t('onboarding.categorySelection.loading') : t('onboarding.categorySelection.continue')}
          </button>
        </div>
      </div>

      {/* Validation modal if more than the required number of categories are selected */}
      {/* {showModal && (
        <Modal
          title={t('onboarding.categorySelection.errorValidationSelectionTitle')}
          message={t('onboarding.categorySelection.errorValidationSelectionMessage', { maxCategories: REQUIRED_CATEGORIES })}
          buttonText={t('onboarding.categorySelection.continue')}
          imageUrl="/assets/img/oops.png"
          onClose={handleModalContinue}
          className="z-50"
        />
      )} */}
    </div>
  );
};

export default CategoryOnboardingPage;
