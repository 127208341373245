// src/components/molecules/ServiceDetails.tsx
import React, { useState } from "react";
import Modal from "@/components/Modals/Modal";
import useServiceRequestStore from '@/store/useServiceRequestStore';
import { set } from "date-fns";
import useServiceStore from "@/store/useServiceStore";

const ServiceDetails: React.FC = () => {
  const { selectedService } = useServiceRequestStore();
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { updateStatus } = useServiceStore();

  console.log(selectedService);
  if (!selectedService) return <p>Debes seleccionar un servicio para ver su información</p>;

  const handleCompleteService = async () => {
    try {
      await updateStatus(selectedService.serviceRequestId, 2);
      setShowCompleteModal(false);
      setShowSuccessModal(true);
    } catch (error) {
      console.error('Error al actualizar el estado:', error);
      // Opcionalmente mostrar un modal de error
    }
  };

  if (!selectedService) {
    return <p>Selecciona un servicio para ver sus detalles</p>;
  }

  return (
    <div className="p-6 bg-cBackground dark:bg-dark-cBackground rounded-lg shadow-md">
      <h2 className="text-xl font-bold">{selectedService.serviceRequestNumber}</h2>
      <p className="text-sm">{selectedService.description}</p>

      {selectedService.serviceRequestStatus === "ACTIVE" && (
        <button
          className="mt-4 px-4 py-2 bg-bgBrand text-textOnBrand rounded-md"
          onClick={() => setShowCompleteModal(true)}
        >
          Finalizar el servicio
        </button>
      )}

      {/* Modal de confirmación */}
      <Modal
        isOpen={showCompleteModal}
        onClose={() => setShowCompleteModal(false)}
        title="Confirmar finalización"
      >
        <p>¿Estás seguro de que deseas finalizar este servicio?</p>
        <div className="flex justify-end mt-4 gap-4">
          <button className="bg-gray-200 px-4 py-2 rounded-md" onClick={() => setShowCompleteModal(false)}>
            Cancelar
          </button>
          <button className="bg-bgBrand text-textOnBrand px-4 py-2 rounded-md" onClick={handleCompleteService}>
            Confirmar
          </button>
        </div>
      </Modal>

      {/* Modal de éxito */}
      <Modal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        title="Servicio finalizado"
      >
        <p>Gracias por completar el servicio. Nos pondremos en contacto contigo.</p>
      </Modal>
    </div>
  );
};

export default ServiceDetails;
