// src/pages/Home.tsx
import React from "react";
import { MainPanelProposal } from "@/components/organisms/MainPanelProposal";

export const ProposalsPage: React.FC = () => {
  return (
    <div className="p-8">
      <h1 className="text-xl font-bold mb-4">Gestión de Solicitudes</h1>
      <MainPanelProposal />
    </div>
  );
};
