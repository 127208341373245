import React, { useEffect, useRef, useState } from "react";
import { CreateServiceRequest, RequestFormValues, UploadImagesRequest } from "@/types/request.types";
import { Formik, Form, ErrorMessage } from "formik";
import { ArrowUpTrayIcon } from "@heroicons/react/24/solid";
import { getDynamicFormSchema } from "@/schemas/dynamicFormSchema";
import { FormField } from "../atoms/FormField";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Button from "@/components/atoms/Button";
import { useTranslation } from "react-i18next";
import useServiceStore from "@/store/useServiceStore";
import useServiceRequestStore from '@/store/useServiceRequestStore';
import { useModalStore } from "@/store/useModalStore";
import Modal from "@/components/Modals/ConfirmationModal";
import useAuthStore from "@/store/useAuthStore";
import LoginModal from "@/components/Modals/LoginModal";




const defaultValues: RequestFormValues = {
  patientName: "",
  phone: "",
  city: 0,
  address: "",
  idealDate: "",
  idealTime: "",
  services: [],
  medicalOrder: null,
  description: "",
  requireHomeService: false,
  requireVirtualService: false,
  requireUrgentService: false,
}; 


export const DynamicForm: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const img = location.state.urlImg;
  
  const [initialValues, setInitialValues] = useState<RequestFormValues>(defaultValues);
  
  const { categoryId, subCategoryId  } = useParams<{ categoryId: string, subCategoryId: string }>();
  const { getServicesBySubCategory, servicesWithAccess, } = useServiceStore();
  const { addServiceRequestWithImages, response, error, resetStore, isLoading } = useServiceRequestStore();
  const { openModal } = useModalStore();
  const { isAuthenticated } = useAuthStore();
  const [guestFormData, setGuestFormData] = useState<RequestFormValues | null>(null);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const formikRef = useRef<any>(null);
  

  useEffect(() => {
    if(categoryId && subCategoryId){
      getServicesBySubCategory( categoryId, subCategoryId);
    }
    if (isAuthenticated) {
      const storage = localStorage.getItem('auth-storage');
      const convertToStorage = JSON.parse(storage || '{}');
      if (convertToStorage) {
        setInitialValues((prevValues) => ({
          ...prevValues,
          patientName: convertToStorage?.state.user?.name + ' ' + convertToStorage?.state.user?.lastName || ' ',
        }));
      }
    }
  },[])

  const validateModeService = (values: RequestFormValues): number => {
    if(values.requireHomeService && values.requireVirtualService){
      alert("No puedes seleccionar ambos servicios");
    }
    if(values.requireHomeService){
      return 1;
    }else if (values.requireVirtualService){
      return 2;
    }else {
      return 0;
    }
  }

 const confirmRequest = (): void => {
  resetStore();
  return navigate('/categories');
  
 }
  const handleConfirmInformation = () => {    
      return openModal('information', {
        title: error ? 'Error en la creación' : 'Operación completada',
        message: error ? 'Ocurrió un error en la creación de la solicitud' :'El servicio ha sido solicitado con éxito', 
        confirmButtonText: 'Aceptar',
        confirmButtonColor: error ? undefined :'bg-dark-bgButtonSuccess',
        onConfirm: () => { return error ? resetStore() : confirmRequest() },
      });
  };

  useEffect(() => {
    if (error || response) {
      return handleConfirmInformation();
    }
  }, [error, response]);

  // Add this effect to handle post-login form submission
  useEffect(() => {
    if (isAuthenticated && guestFormData) {
      handleSubmit(guestFormData);
      setGuestFormData(null);
    }
  }, [isAuthenticated]);

  // Add this function to handle guest form submission
  const handleGuestSubmit = (values: RequestFormValues) => {
    setGuestFormData(values);
    setShowLoginModal(true);
  };

  const handleSubmit = async (values: RequestFormValues) => {
    validateModeService(values);
    try {
      const newObject: CreateServiceRequest = {
        patientName: values.patientName,
        priority: values.requireUrgentService ? 1 : 0,
        isDirect: false,
        requestDate: new Date().toISOString(),
        serviceNeededBy: values.idealTime ? new Date(`${values.idealDate}T${values.idealTime}Z`) : new Date(values.idealDate).toISOString(),
        distanceFromCompany: 0,
        description: values.description || "",
        phoneNumber: values.phone,
        cityId: 12,
        serviceModeId: validateModeService(values),
        address: values.address,
        services: values.services.map((serviceId) => ({ serviceId })),
      }
      const imagesData: UploadImagesRequest = { files: [values.medicalOrder as File] };
      await addServiceRequestWithImages(newObject, imagesData);
    
    } catch (error) {
      console.error("Error al enviar el formulario:", error);
    }
  };

  const dynamicFormSchema = getDynamicFormSchema(t);

  const needsOrder = (exams: string[], state?: string): boolean => {
    if (!state) {
      return exams.some((examId) =>
        servicesWithAccess.some((service) => service.serviceId === examId && service.requiredFile)
      );
    }
  
    return exams.some((examId) => {
      const service = servicesWithAccess.find((service) => service.serviceId === examId);
      if (!service) return false;
      
      switch (state) {
        case 'canBeDoneAtHome':
          return service.canBeDoneAtHome;
        case 'canBeDoneVirtually':
          return service.canBeDoneVirtually;
        default:
          return false;
      }
    });
  };

  const handleChangeSelectServices = (e: any) => {
    const value = e.target.value;
    if(formikRef.current.values.services.includes(value)){
      e.target.value = "";
      return;
    }else {
      if(e.target.value){
        const newExams = [
          ...formikRef.current.values.services,
          e.target.value,
        ];
        formikRef.current.setFieldValue("services", newExams);
        e.target.value = "";
      }
      e.target.value = "";
      return;
    }
  }

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={dynamicFormSchema}
        onSubmit={isAuthenticated ? handleSubmit : handleGuestSubmit}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        {({ errors, touched, setFieldValue, values }) => {
          
          return (
          <Form className="w-full mx-auto py-2 lg:py-6 bg-white rounded-lg">
            <div className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6  ">
                <div className="space-y-6">
                  {/* Patient Name */}
                  <FormField
                    label="Nombre"
                    name="patientName"
                    type="text"
                    placeholder="Ingresa tu nombre completo"
                    className="border border-gray-300 rounded-md"
                  />
                  {/* Phone */}
                  <FormField
                    label="Teléfono de contacto"
                    name="phone"
                    placeholder="Ingresa tu número de teléfono"
                    type="text"
                    className="border border-gray-300 rounded-md"
                  />
                  {/* City */}
                  <FormField
                    label="Ciudad"
                    name="city"
                    as="select"
                    // placeholder="Medellin Colombia"
                    options={[{ value: 0, label: "Selecciona una Ciudad", disabled: true, },{ value: 12, label: "Medellin" }]}
                    className="border border-gray-300 rounded-md"
                  />
                  {/* Address */}
                  <FormField
                    label="Dirección"
                    name="address"
                    placeholder="Ingresa tu dirección"
                    className="border border-gray-300 rounded-md"
                  />
                  {/* Date and Time */}
                  <FormField
                    type="date"
                    label="Fecha ideal"
                    name="idealDate"
                    className="border border-gray-300 rounded-md"
                  />
                  <FormField
                    label="Hora ideal"
                    name="idealTime"
                    type="time"
                    className="border border-gray-300 rounded-md"
                  />
                  {/* Exam Selection */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 text-left lg:mb-0 mb-4">
                      Elige los servicios que deseas solicitar
                    </label>
                    <div className="mt-2 space-y-2">
                      {values.services?.map((exam) => (
                        <div
                          key={exam}
                          className="flex items-center justify-between p-2 bg-gray-50 rounded border border-gray-300"
                        >
                          <span className="text-[14px] text-gray-400">
                            {servicesWithAccess.find((s: any) => s.serviceId === exam)?.name}
                          </span>
                          <button
                            type="button"
                            onClick={() => {
                              const newExams = values.services.filter(
                                (e) => e !== exam
                              );
                              setFieldValue("services", newExams);
                            }}
                            className="text-red-500 hover:text-red-700"
                          >
                            ×
                          </button>
                        </div>
                      ))}
                      <select
                        name="services"
                        onChange={(e) => handleChangeSelectServices(e)}
                        className=" text-[14px] truncate mt-1 block w-full rounded-md border border-gray-500shadow-sm focus:border-blue-500 focus:ring-blue-500 placeholder-cTextSecondary px-3 py-2"
                      >
                        <option className="text-[12px]" value="">Elige tu servicio</option>
                        {servicesWithAccess
                          .map((service) => (
                            <option className=" w-full truncate text-[12px]" key={service.serviceId} value={service.serviceId}>
                              {service.name}
                            </option>
                          ))}
                      </select>
                      <ErrorMessage
                        name="services"
                        component="div"
                        className="text-red-500 text-[8px] mt-1"
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-6">
                  {/* Medical Order Upload */}
                  {needsOrder(values.services, undefined) && (
                    <div>
                      <label className="block text-sm font-medium text-gray-700 text-left">
                        Adjunta tu orden médica
                      </label>
                      <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                        <div className="space-y-1 text-center">
                          {values.medicalOrder ? (
                            <div className="flex flex-col items-center">
                              <div className="flex items-center space-x-2">
                                <ArrowUpTrayIcon className="size-8 text-green-500" />
                                <span className="text-sm text-gray-600">
                                  {values.medicalOrder.name}
                                </span>
                              </div>
                              <button
                                type="button"
                                onClick={() =>
                                  setFieldValue("medicalOrder", null)
                                }
                                className="mt-2 text-sm text-red-500 hover:text-red-700"
                              >
                                Eliminar archivo
                              </button>
                            </div>
                          ) : (
                            <>
                              <ArrowUpTrayIcon className="mx-auto size-12 text-gray-400" />
                              <div className="flex text-sm text-gray-600">
                                <label className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                                  <span>Click aquí para subir el archivo</span>
                                  <input
                                    type="file"
                                    className="sr-only"
                                    onChange={(event) => {
                                      const file =
                                        event.currentTarget.files?.[0] || null;
                                      if (file) {
                                        // Verificar el tamaño del archivo (2MB = 2 * 1024 * 1024 bytes)
                                        if (file.size > 2 * 1024 * 1024) {
                                          alert(
                                            "El archivo no puede ser mayor a 2MB"
                                          );
                                          return;
                                        }
                                        // Verificar el tipo de archivo
                                        const allowedTypes = [
                                          "image/jpeg",
                                          "image/png",
                                          "application/pdf",
                                        ];
                                        if (!allowedTypes.includes(file.type)) {
                                          alert(
                                            "Solo se permiten archivos JPG, PNG y PDF"
                                          );
                                          return;
                                        }
                                      }
                                      setFieldValue("medicalOrder", file);
                                    }}
                                    accept=".pdf,.jpg,.jpeg,.png"
                                  />
                                </label>
                              </div>
                              <p className="text-xs text-gray-500">
                                El documento no puede pesar mas de 2mb
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                      <ErrorMessage
                        name="medicalOrder"
                        component="div"
                        className="text-red-500 text-sm mt-1"
                      />
                    </div>
                  )}
                  {/* Description */}
                  <FormField
                    label="¿Tienes algo que agregar?"
                    name="description"
                    as="textarea"
                    rows={12}                    
                    className="text-[14px] leading-[24px] text-gray-500 font-normal border border-gray-300 rounded-md "
                    
                  />
                  {/* Service Options */}
                  {needsOrder(values.services, 'canBeDoneAtHome') && 
                    (
                      <FormField
                        label="Requiero el examen en mi domicilio"
                        name="requireHomeService"
                        as="switch"
                        value={values.requireHomeService}
                        onChange={(value) => { setFieldValue("requireHomeService", value)}}
                      />
                    )
                  }
                  {needsOrder(values.services, 'canBeDoneVirtually') && 
                    (
                      <FormField
                        label="Requiero el examen de manera virtual"
                        name="requireVirtualService"
                        as="switch"
                        value={values.requireVirtualService}
                        onChange={(value) => setFieldValue("requireVirtualService", value)}
                      />
                    )
                  }

                </div>
                <div className=" flex flex-col items-center">
                  <label className="text-sm self-start font-medium text-gray-700 mb-1">Poner aqui la subcategoria</label>
                  {/* Image Placeholder */}
                  <div className="w-full h-[200px] lg:h-[500px] bg-gray-200 rounded-md mb-8">
                    
                      <img src={img} alt="img" className="w-full h-[200px] lg:h-full overflow-hidden object-cover rounded-xl "></img>
                  
                  </div>
                  {/* Urgent Service */}
                  <div className="w-full flex justify-between items-center">
                  <FormField
                    label="Servicio Urgente"
                    name="requireUrgentService"
                    as="switch"
                    value={values.requireUrgentService}                    
                    onChange={(value) => setFieldValue("requireUrgentService", value)}
                  />
                  {/* Submit Button */}
                  <Button
                    type="submit"
                    variant="filled"
                    size="medium"
                    disabled={isLoading}
                    className="inline-flex justify-center py-2 px-4"
                  >
                    SOLICITAR
                  </Button>

                  </div>

                </div>
              </div>
            </div>
          </Form>
        )}}
      </Formik>
      {showLoginModal && (
        <LoginModal
          isOpen={showLoginModal}
          onClose={() => setShowLoginModal(false)}
        />
      )}
      <Modal
      />
    
    </>
    
  );
};


export default DynamicForm;
