import { xervixApi } from '@/config/axios.config';
import { API_ROUTES } from '@/constants/apiRoutes';
import { Statistic } from '@/types/statistic.types';

const getStatistics = async (): Promise<Statistic> => {
  const response = await xervixApi.get(API_ROUTES.STATISTICS.FETCH_PROFESSIONAL_STATISTICS);
  return response.data;
};

export default {
  getStatistics,
};