// @style
import "./StyleSpinner.css"

// @store
import { useSpinnerStore } from "@/store/useSpinnerStore"

const Spinner = () => {
    const { open } = useSpinnerStore();

    return (
        <div className={!open ? "hidden" : "fixed w-full h-screen bg-transparent z-[100]"}>
            <div className="flex items-center justify-center h-screen w-full bg-black/50 pointer-events-none">
                <div className="loader"></div>
            </div>
        </div>
    )
}

export default Spinner