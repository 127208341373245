// @vendors
import React from "react";
import { useTranslation } from "react-i18next";

import { ThemeToggle } from "@/components/atoms/ThemeToggle";
import { LanguageToggle } from "@/components/atoms/LanguageToggle";

// @store
import useAuthStore from "@/store/useAuthStore";

// @components
import LoginForm from "@/components/organisms/LoginForm";

interface LoginModalProps {
  onClose?: () => void;
  onSuccess?: () => void;
}

const Login: React.FC<LoginModalProps> = ({ onClose, onSuccess }) => {
  const { t } = useTranslation();
  useAuthStore();

  return (
    <div className="flex min-h-screen dark:bg-dark-cBackground">
      <div className="absolute top-4 right-4 flex items-center gap-2 z-10">
        <ThemeToggle />
        <LanguageToggle />
      </div>
      <div
        className="hidden md:flex md:w-2/3 bg-cover bg-center relative"
        style={{
          backgroundImage: "url(/assets/img/backgrounds/login-side-img.jpeg)",
        }}
      >
        <div className="absolute inset-0 flex flex-col items-center justify-start top-16">
          <h1 className="text-6xl font-bold mb-4 text-bgBrand">XERVIX</h1>
          <p className="text-2xl font-light text-dark-cText">
            {t("loginPage.services")}
          </p>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center md:w-1/3 w-full p-8">
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
