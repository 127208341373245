import React, { useState } from "react";
import Modal from "../components/Modals/Modal";

import CategoriesSlider from "../components/organisms/landing-page/CategoriesSlider";
import ServicesHero from "../components/organisms/landing-page/ServicesHero";
import MarketplaceHero from "../components/organisms/landing-page/MarketplaceHero";
import TopServicesHero from "../components/organisms/landing-page/TopServicesHero";
import JoinCommunity from "../components/organisms/landing-page/JoinCommunityHero";
import AppDownloadHero from "../components/organisms/landing-page/AppDownloadHero";
import ContactHero from "../components/organisms/landing-page/ContactHero";
import ServiceSearchField from "../components/molecules/ServiceSearchComponent";
import { useNavigate } from "react-router-dom";
import ViewAllButton from "../components/molecules/ViewAllButton";


const LandingPage = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  
  return (
      <div>
        <Modal isOpen={isModalOpen} onClose={handleCloseModal} title="En Reparación">
          <p>Esta funcionalidad está en reparación. Por favor, inténtelo más tarde.</p>
        </Modal>
        <div className="flex flex-col gap-0 xl:gap-2 px-6">
          <ServiceSearchField />
          <ViewAllButton />
          <CategoriesSlider />
          <ServicesHero onButtonClick={handleOpenModal} />
          <TopServicesHero onButtonClick={handleOpenModal} />
          <MarketplaceHero onButtonClick={handleOpenModal} />
          <JoinCommunity onJoinClick={handleOpenModal} />
          <AppDownloadHero onButtonClick={handleOpenModal} />
          <ContactHero />
        </div>
      </div>
  );
};

export default LandingPage;
