import React from "react";
import ActiveServices from "@/components/organisms/services/ActiveServices";
import CompletedServices from "@/components/organisms/services/CompletedServices";

const MyServicesPage: React.FC = () => {
  return (
    <div className="w-full m-auto p-6 space-y-5">
        <ActiveServices />
        <CompletedServices />
    </div>
  );
};

export default MyServicesPage;
