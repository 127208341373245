export const API_ROUTES = {
    ACCOUNT: {
        LOGIN: '/Account/login',
        REGISTER: '/Account/register',
        RESET_PASSWORD: '/Account/reset-password',
        VERIFY_CODE: '/Account/verify-code',
        RESEND_VERIFICATION_CODE_EMAIL: '/Account/resend-verification-code-email',
        FACEBOOK_LOGIN: '/Account/facebook-login',
        GOOGLE_LOGIN: '/Account/google-login',
        GOOGLE_REGISTER: '/Account/google-sign-up',
        REFRESH_TOKEN: '/Account/refresh-token',
      },
      CATEGORIES: {
        FETCH_CATEGORIES: '/Categories',
        FETCH_CATEGORY: (categoryId: string) => `/Categories/${categoryId}`,
        SEARCH_CATEGORIES: '/Categories/search',
        FETCH_CATEGORIES_HIERARCHY: '/Categories/hierarchy-summarys?IsActive=true',
      },
      POST: {
        CREATE_POST: '/post',
        GET_BY_PROFESSIONAL: (professionalId: string) => `/post/by-professional/${professionalId}`,
        GET_BY_ID: (postId: string) => `/post/${postId}`,
        DELETE_POST: (postId: string) => `/post/delete/${postId}`,
      },
      PROFESSIONAL: {
        UPSERT_BUSINESS_INFO: '/Professionals/upsert-business-info',
        UPDATE_REFERAL_EMAIL: '/Professionals/update-referal-email',
        ACCEPT_TERMS_AND_CONDITIONS: '/Professionals/accept-terms-and-conditions',
        COMPLETE_ONBOARDING: '/Professionals/complete-onboarding',
        SUBMIT_CATEGORIES: '/Professionals/bulk-categories',
        SUBMIT_SERVICES: '/Professionals/bulk-services',
        UPDATE_LOCATION: '/Professionals/update-location',
        UPLOAD_DOCUMENTS: '/ProfessionalDocuments/upload-documents',
        FETCH_AVAILABLE_SERVICES: '/Professionals/available-services',
      },
      PROPOSAL: {
        /* FETCH_PROPOSALS: '/Proposals',
        FETCH_PROPOSAL: (proposalId: string) => `/Proposals/${proposalId}`,
        FETCH_PROPOSAL_BY_REQUEST: (serviceRequestId: string) => `/Proposals/by-service-request/${serviceRequestId}`, */
        FETCH_BY_SERVICE_REQUEST: (serviceRequestId: string) => `/Proposal/by-service-request-id/${serviceRequestId}`,
        SUBMIT_PROPOSAL: '/Proposal/add-proposal',
        UPDATE_STATUS: (proposalId: string) => `/Proposal/${proposalId}/status`,
        FETCH_PROPOSAL_DETAIL: (proposalId: string) => `/Proposal/${proposalId}`,
      },
      REQUEST: {
        FETCH_REQUESTS: '/service-request',
        SUBMIT_REQUEST: '/service-request',
        FETCH_REQUEST_DETAILS: '/service-request/details',
        MODE: '/service-request',
        UPLOAD_IMAGES: (serviceRequestId: string) => `/service-request/${serviceRequestId}/upload-images`,
        FETCH_ACTIVE_FOR_PROFESSIONAL: '/service-request/active-for-professional',

      },
      SERVICES: {
        FETCH_SERVICES: '/Services',
        FETCH_SERVICE: (serviceId: string) => `/Services/${serviceId}`,
        BY_CATEGORY_SUBCATEGORY: `/Services/by-categoryId-subCategoryId`,
        FETCH_TOP_SERVICES: '/service-request/get-services-request-popular',
        FETCH_SERVICE_STATUS: (serviceRequestId: string) => `/service-request/${serviceRequestId}/status`,
      },
      EVALUATION: {
        SUBMIT_EVALUATION: '/request-evaluation',
        FETCH_EVALUATION_DETAILS: '/request-evaluation/details',
        FETCH_EVALUATION_PROFESSIONAL: (profesionalId: string) => `/request-evaluation/professional/${profesionalId}`,
        FETCH_EVALUATION: (serviceRequestId: string) => `/request-evaluation/${serviceRequestId}`,
        DELETE_EVALUATION: (evaluationId: string) => `/request-evaluation/${evaluationId}`,
        PATCH_EVALUATION: (evaluationId: string) => `/request-evaluation/activate/${evaluationId}`,
      },
      ENUMS: {
        SERVICE_REQUEST_STATUSES: '/enums/service-request-statuses',
        SERVICE_MODES: '/enums/service-modes',
      },
      PROFILE: {
        GET_BY_USER_TYPE_INFO: (userType: string) => `/profile/user-type/${userType}`,
        GET_PROFESSIONAL_INFO: (professionalId: string) => `/profile/admon/professional-info/${professionalId}`,
      },
      BUSINESS_TYPES: '/BusinessTypes',
      STATISTICS: {
        FETCH_PROFESSIONAL_STATISTICS: `statistics/`,

      },
};