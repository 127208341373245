// src/components/atoms/ServiceCard.tsx
import React from "react";
import useServiceRequestStore from "@/store/useServiceRequestStore";
import { ServiceRequest } from "@/types/request.types";
import { formatTimeAgo } from '@/helpers/date';



interface PeopleServiceCardProps {
  service: ServiceRequest;
  onClick: () => void;
}

const PeopleServiceCard: React.FC<PeopleServiceCardProps> = ({ service, onClick }) => {

  const { setSelectedService } = useServiceRequestStore();
  const getStatusClass = (requestStatus: string) =>
    requestStatus === "ACTIVE" || "COMPLETED" ? "bg-bgSuccess text-textOnSuccess" : "bg-bgDisabled text-textDisabled";

  const handleClick = () => {
    setSelectedService(service);
  };
  return (
    <div
      className="flex items-center justify-between p-4 bg-cBackground dark:bg-dark-cBackground rounded-lg shadow-md hover:shadow-lg select-none transition"
      onClick={onClick}
    >
      <div className="flex items-center gap-4">
        <img
          src={`https://eu.ui-avatars.com/api/?name=${service.customer?.names || 'User'}`}
          alt={service.customer?.names}
          className="w-12 h-12 rounded-full object-cover"
        />
        <div>
          <p className="font-bold">{`${service.customer?.names || ''} ${service.customer?.lastNames || ''}`}</p>
          <p className="text-sm text-cTextSecondary">{service.services[0]?.name || 'Sin servicio'}</p>
          <p className="text-xs text-cTextSecondary">{service.requestDate}</p>
        </div>
      </div>
      <div>
        <p className="text-sm text-cTextSecondary">{service.address}</p>
        <button
          className={`px-4 py-1 text-sm font-medium rounded-md ${getStatusClass(service.serviceRequestStatus)}`}
          onClick={handleClick}
        >
          ABRIR
        </button>
      </div>
    </div>
  );
};

export default PeopleServiceCard;
