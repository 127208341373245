import { create } from 'zustand';
import postService from '@/services/post.service';
import { CreatePostRequest, Post, PostResponse } from '@/types/post.types';

interface PostStoreState {
  posts: Post[] | null;
  selectedPost: Post | null;
  isLoading: boolean;
  error: string | null;
  createPost: (data: CreatePostRequest) => Promise<void>;
  getPostsByProfessional: (professionalId: string, isActive?: boolean) => Promise<void>;
  getPostById: (postId: string) => Promise<void>;
  deletePost: (postId: string) => Promise<void>;
  setSelectedPost: (post: Post | null) => void;
}

const usePostStore = create<PostStoreState>((set) => ({
  posts: [],
  selectedPost: null,
  isLoading: false,
  error: null,

  createPost: async (data) => {
    set({ isLoading: true, error: null });
    try {
      await postService.createPost(data);
      set({ isLoading: false });
    } catch (error) {
      set({ 
        error: error instanceof Error ? error.message : 'Error creating post',
        isLoading: false 
      });
    }
  },

  getPostsByProfessional: async (professionalId: string, isActive?: boolean) => {
    set({ isLoading: true, error: null });
    try {
      const posts = await postService.getPostsByProfessional(professionalId, isActive);
      set({ posts, isLoading: false });
    } catch (error) {
      set({ 
        error: error instanceof Error ? error.message : 'Error fetching posts',
        isLoading: false 
      });
    }
  },

  getPostById: async (postId) => {
    set({ isLoading: true, error: null });
    try {
      const post = await postService.getPostById(postId);
      set({ selectedPost: post, isLoading: false });
    } catch (error) {
      set({ 
        error: error instanceof Error ? error.message : 'Error fetching post',
        isLoading: false 
      });
    }
  },

  deletePost: async (postId) => {
    set({ isLoading: true, error: null });
    try {
      await postService.deletePost(postId);
      set((state) => ({
        posts: state.posts?.filter((post) => post.postId !== postId) || [],
        isLoading: false,
      }));
    } catch (error) {
      set({ 
        error: error instanceof Error ? error.message : 'Error deleting post',
        isLoading: false 
      });
    }
  },

  setSelectedPost: (post) => set({ selectedPost: post }),
}));

export default usePostStore;