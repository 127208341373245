import React, { useState } from 'react';
import MessagesTab from '@/components/molecules/sidebarRequest/MessagesTab';
import MarketplaceTab from '@/components/molecules/sidebarRequest/MarketplaceTab';
import ReviewsTab from '@/components/molecules/sidebarRequest/ReviewsTab';
import RequestsSection from '@/components/molecules/sidebarRequest/RequestsSection';
import ProposalsSection from '@/components/molecules/sidebarRequest/ProposalsSection';
import { BaseLayout } from '@/components/templates/BaseLayout';
import { Proposal } from '@/types/proposal.types';
import useProposalStore from '@/store/useProposalStore';
import { ServiceRequest } from '@/types/request.types';

const MyRequestsPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState('requests');
  const [selectedRequest, setSelectedRequest] = useState<ServiceRequest | null>(null);
  const { data: proposals } = useProposalStore();

  const renderTabContent = () => {
    switch (activeTab) {
      case 'proposal':
        return 'componente de propuesta';
        case 'profile':
        return 'componente de perfil';
      case 'messages':
        return <MessagesTab />;
      case 'marketplace':
        return <MarketplaceTab />;
      case 'reviews':
        return <ReviewsTab />;
      default:
        return null;
    }
  };

  return (
    <div className="w-full grid grid-cols-2 divide-x divide-gray-200 gap-4">
     <RequestsSection onSelectRequest={(request) => setSelectedRequest(request)}/>
     <ProposalsSection proposals={proposals?.items || []} />
    </div>
  );
};

export default MyRequestsPage;