import { xervixApi } from '@/config/axios.config';
import { API_ROUTES } from '@/constants/apiRoutes';
import { ProposalDetail } from '@/types/proposal.types';

interface CreateProposal {
  serviceRequestId: string;
  proposalValue: number;
  daysToComplete: number;
  descriptionProposet: string;
}

const addProposal = async (data: CreateProposal): Promise<any> => {
  const response = await xervixApi.post(API_ROUTES.PROPOSAL.SUBMIT_PROPOSAL, data);
  return response.data;
};

const getProposalsByServiceRequest = async (
  serviceRequestId: string,
  pageNumber = 1,
  pageSize = 10,
  sortDirection = "desc",
  proposalStatus?: number
): Promise<any> => {
  const response = await xervixApi.get(
    API_ROUTES.PROPOSAL.FETCH_BY_SERVICE_REQUEST(serviceRequestId),
    {
      params: { pageNumber, pageSize, sortDirection, proposalStatus },
    }
  );
  return response.data;
};

const updateProposalStatus = async (proposalId: string, proposalState: number): Promise<any> => {
  const response = await xervixApi.patch(
    API_ROUTES.PROPOSAL.UPDATE_STATUS(proposalId),
    null,
    { params: { proposalState } }
  );
  return response.data;
};

const getProposalDetail = async (proposalId: string): Promise<ProposalDetail> => {
  const response = await xervixApi.get(API_ROUTES.PROPOSAL.FETCH_PROPOSAL_DETAIL(proposalId));
  return response.data;
};

export default {
  addProposal,
  getProposalsByServiceRequest,
  updateProposalStatus,
  getProposalDetail,
};