import { formatDistanceToNow, format, parseISO } from "date-fns";
import { es } from "date-fns/locale";

export const formatTimeAgo = (date: string): string => {
  return formatDistanceToNow(new Date(date), { addSuffix: true, locale: es });
};

export const formatTime = (dateString: string): string => {
  const date = new Date(dateString);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

export const formatDate = (dateString: string): string => {
  const date = parseISO(dateString);
  return format(date, "d 'de' MMM. 'de' yyyy", { locale: es });
};