import { create } from "zustand";
import { persist } from "zustand/middleware";
import requestServiceService from "@/services/request.service";
import { ServiceRequest } from "@/types/request.types";
import useServiceRequestStore from "./useServiceRequestStore";

import {
  CreateServiceRequest,
  UploadImagesRequest,
  RequestFilters,
  ServiceRequestDetailsResponse,
  ServiceRequestResponse,
} from "@/types/request.types";

interface ServiceRequestStoreState {
  isLoading: boolean;
  error: string | null;
  response: any | null;
  filters: RequestFilters;
  data: ServiceRequestDetailsResponse | null;
  activeForProfessional: ServiceRequestResponse | null;
  selectedService: ServiceRequest | null;
  activeServices: ServiceRequest[];
  completedServices: ServiceRequest[];
  setSelectedService: (service: ServiceRequest) => void;
  fetchActiveForProfessional: (filters: RequestFilters) => Promise<void>;
  fetchDetails: (
    page?: number,
    pageSize?: number,
    orderBy?: string,
    sortDirection?: string
  ) => Promise<void>;
  setFilters: (filters: Partial<RequestFilters>) => void;
  getFilteredRequests: () => Promise<void>;
  resetStore: () => void;
  addServiceRequestWithImages: (
    payload: CreateServiceRequest,
    imagesPayload: UploadImagesRequest
  ) => Promise<void>;
  getRequestsByUser: () => Promise<void>;
  getRequestsByStatus: (
    serviceRequestId: string,
    serviceRequestStatus: string
  ) => Promise<void>;
  getCompletedServices: () => Promise<void>;
  cancelService: (serviceId: string) => Promise<void>;
}

const useServiceStore = create<ServiceRequestStoreState>()(
  persist(
    (set, get) => ({
      isLoading: false,
      error: null,
      response: null,
      data: null,
      filters: {
        pageSize: 10,
        pageNumber: 1,
        sortBy: "requestDate",
        sortOrder: "desc", // Add default sorting
      },
      activeServices: [],
      completedServices: [],
      activeForProfessional: null,
      selectedService: null,
      setSelectedService: (service) => set({ selectedService: service }),
      fetchActiveForProfessional: async (filters: RequestFilters) => {
        set({ isLoading: true });
        try {
          const response = await requestServiceService.fetchActiveForProfessional(filters);
          set({ activeForProfessional: response, isLoading: false });
        } catch (error) {
          set({ 
            error: error instanceof Error ? error.message : 'Error fetching active requests',
            isLoading: false 
          });
        }
      },
      fetchDetails: async (
        page = 1,
        pageSize = 10,
        orderBy = "createdDate",
        sortDirection = "desc"
      ) => {
        try {
          set({ isLoading: true, error: null });
          const response =
            await requestServiceService.fetchServiceRequestDetails(
              page,
              pageSize,
              orderBy,
              sortDirection
            );
          set({ data: response, isLoading: false });
        } catch (error: any) {
          set({ error: error.message, isLoading: false });
        }
      },
      setFilters: (newFilters) => {
        set((state) => ({
          filters: { ...state.filters, ...newFilters },
        }));
      },

      getFilteredRequests: async () => {
        set({ isLoading: true, error: null });
        try {
          const response = await requestServiceService.fetchFilteredRequests(
            get().filters
          );
          set({ response, isLoading: false });
        } catch (error: unknown) {
          const errorMessage =
            error instanceof Error
              ? error.message
              : "An unknown error occurred";
          set({ isLoading: false, error: errorMessage });
        }
      },
      resetStore: () => set({ isLoading: false, error: null, response: null }),
      getRequestsByUser: async () => {
        set({ isLoading: true, error: null, response: null });
        try {
          const response = await requestServiceService.fetchRequestsByUser();
          set({ isLoading: false, response });
        } catch (error: unknown) {
          const errorMessage =
            error instanceof Error
              ? error.message
              : "An unknown error occurred";
          set({ isLoading: false, error: errorMessage });
        }
      },

      getRequestsByStatus: async (
        serviceRequestId: string,
        serviceRequestStatus: string
      ) => {
        set({ isLoading: true, error: null, response: null });
        try {
          const response = await requestServiceService.fetchRequestByStatus(
            serviceRequestId,
            serviceRequestStatus
          );
          set({ isLoading: false, response });
        } catch (error: unknown) {
          const errorMessage =
            error instanceof Error
              ? error.message
              : "An unknown error occurred";
          set({ isLoading: false, error: errorMessage });
        }
      },

      addServiceRequestWithImages: async (payload, imagesPayload) => {
        set({ isLoading: true, error: null, response: null });
        try {
          const { serviceRequestId } =
            await requestServiceService.submitServiceRequest(payload);
          if (imagesPayload.files.length > 0) {
            await requestServiceService.uploadImagesServiceRequest(
              serviceRequestId,
              imagesPayload
            );
          }
          set({
            isLoading: false,
            response: `ServiceRequest creado y las imágenes subidas con ID: ${serviceRequestId}`,
          });
        } catch (error: any) {
          if (error.response) {
            set({ isLoading: false, error: error.response.status.toString() });
          } else {
            set({ isLoading: false, error: "An unknown error occurred" });
          }
        }
      },
      getCompletedServices: async () => {
        set({ isLoading: true });
        try {
          const response = await requestServiceService.fetchRequestsByUser();
          const completedServices = response.items.filter(
            (service: ServiceRequest) => String(service.serviceRequestStatus) === "2"
          );
          set({ completedServices, isLoading: false });
        } catch (error) {
          set({ error: 'Error fetching completed services', isLoading: false });
        }
      },
    
      cancelService: async (serviceId: string) => {
        set({ isLoading: true });
        try {
          await requestServiceService.fetchRequestByStatus(serviceId, "3");
          await useServiceRequestStore.getState().getRequestsByUser(); // Only refresh active services
          set({ isLoading: false });
        } catch (error) {
          set({ error: 'Error canceling service', isLoading: false });
        }
      }
    }),
    {
      name: "serviceRequest-store",
      partialize: (state) => ({
        isLoading: state.isLoading,
        error: state.error,
        response: state.response,
      }),
    }
  )
);

export default useServiceStore;
