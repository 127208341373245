import React, { useEffect } from "react";
import { useState } from "react";
import { HeartIcon, TrashIcon } from "@heroicons/react/24/outline";
import usePostStore from "@/store/usePostStore";
import useStatisticStore from "@/store/useStatisticStore";
import useProfileStore from "@/store/useProfileStore";
import { formatDate } from "@/helpers/date";
import routesConfig from "@/config/routes.config";
import { useNavigate } from "react-router-dom";



interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({ children, className, ...props }) => {
  return (
    <button
      className={`px-4 py-2 rounded-md font-semibold transition-all duration-200 ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

const Card: React.FC<CardProps> = ({ children, className }) => {
  return (
    <div className={`bg-white rounded-lg shadow-md p-4 ${className}`}>
      {children}
    </div>
  );
};

interface CardContentProps {
  children: React.ReactNode;
}

const CardContent: React.FC<CardContentProps> = ({ children }) => {
  return <div className="p-2">{children}</div>;
};

const MarketplaceDashboard = () => {
  const navigate = useNavigate();
  const { posts, getPostsByProfessional, deletePost } = usePostStore();
  const { statistics, fetchStatistics } = useStatisticStore();
  const [sortOrder, setSortOrder] = useState("latest");
  const { professional, getProfessionalTypeInfo } = useProfileStore();
  console.log(posts);

  useEffect(() => {
    getProfessionalTypeInfo(); // Reemplaza con el ID del profesional real
  }, [getProfessionalTypeInfo]);

  useEffect(() => {
    // Reemplaza con el ID del profesional real
    if (professional?.professionalId) {
        getPostsByProfessional(professional.professionalId);
        fetchStatistics();
    }
  }, [professional, getPostsByProfessional, fetchStatistics]);

  const handleDeletePost = async (postId: string) => {
    await deletePost(postId);
  }

  const handleCreateReels = () => {
    navigate(routesConfig.createPostProfessional);
  }

  return (
    <div className="p-6 bg-cBackground text-cText min-h-screen flex flex-col items-center">
      <div className="bg-cBackgroundSecondary p-8 rounded-xl shadow-lg w-full max-w-4xl">
        <div className="flex flex-col items-center">
          <img
            src={
                professional?.companyImage ||
                `https://eu.ui-avatars.com/api/?name=${
                  professional?.name || "User"
                }+${professional?.lastNames || ""}&size=250`
              }
            alt="Profile"
            className="w-24 h-24 rounded-full border-4 border-bgBrand"
          />
          <h1 className="text-xl font-bold mt-4">{professional?.name} {professional?.lastNames}</h1>
          <div className="flex space-x-6 mt-2 text-center">
            <div>
              <p className="text-lg font-semibold text-bgBrand">
                {statistics?.Followers}
              </p>
              <p className="text-cTextSecondary">Seguidores</p>
            </div>
            <div>
              <p className="text-lg font-semibold text-bgBrand">
                {statistics?.Quotes}
              </p>
              <p className="text-cTextSecondary">Cotizaciones</p>
            </div>
            <div>
              <p className="text-lg font-semibold text-bgBrand">
                {statistics?.ServicesCompleted}
              </p>
              <p className="text-cTextSecondary">Servicios Completados</p>
            </div>
            <div>
              <p className="text-lg font-semibold text-bgBrand">
                {statistics?.Likes}
              </p>
              <p className="text-cTextSecondary">Likes</p>
            </div>
            <div>
              <p className="text-lg font-semibold text-bgBrand">
                {statistics?.Sales}
              </p>
              <p className="text-cTextSecondary">Ventas</p>
            </div>
            <div>
              <p className="text-lg font-semibold text-bgBrand">
                {statistics?.ReelsPosted}
              </p>
              <p className="text-cTextSecondary">Reels Publicados</p>
            </div>
          </div>
        </div>

        <div className="mt-8 flex justify-between items-center">
          <h2 className="text-lg font-semibold">Publicaciones</h2>
          <button
            onClick={() =>
              setSortOrder(sortOrder === "latest" ? "oldest" : "latest")
            }
            className="text-sm text-bgBrand hover:underline"
          >
            Ordenar
          </button>
        </div>

        <div className="grid grid-cols-2 gap-4 mt-4">
          {posts && posts.map((post) => (
              <Card key={post.postId} className="relative">
                <CardContent>
                  <img
                    src={post?.image}
                    alt={post.title}
                    className="rounded-lg"
                  />
                  <h3 className="font-semibold mt-2">{post.description}</h3>
                  <p className="text-cTextSecondary text-sm">
                    {formatDate(post.createdAt)}
                  </p>
                  <div className="flex justify-between items-center mt-2 text-cTextSecondary text-sm">
                    <span>
                      {post.likes} <HeartIcon className="w-4 h-4 inline" />
                    </span>
                    <span>{post.comments} 💬</span>
                  </div>
                  <button
                    onClick={() => handleDeletePost(post.postId)}
                    className="absolute top-2 right-2 bg-bgError text-white px-2 py-1 rounded-md"
                  >
                    <TrashIcon className="w-4 h-4" /> Eliminar
                  </button>
                </CardContent>
              </Card>
            ))}
        </div>
      </div>

      <div className="mt-6">
        <Button className="bg-bgBrand text-white px-6 py-3 rounded-lg shadow-lg"
        onClick={handleCreateReels}>
          CREAR REELS
        </Button>
      </div>
    </div>
  );
};

export default MarketplaceDashboard;
