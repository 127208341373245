import { xervixApi } from '../config/axios.config';
import { API_ROUTES } from '../constants/apiRoutes';
import { LoginResponse, RegisterRequest } from '../types/auth.types';


// Login function
const login = async (email: string, password: string): Promise<LoginResponse> => {
  try {
    const response = await xervixApi.post<LoginResponse>(`${API_ROUTES.ACCOUNT.LOGIN}`, { email, password }, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      throw new Error('Invalid credentials');
    }
    if (error.response?.status === 404) {
      throw new Error('User not found');
    }
    throw new Error('An error occurred during login');
  }
};

// Register function
const register = async (data: RegisterRequest): Promise<LoginResponse> => {
  try {
    const response = await xervixApi.post<LoginResponse>(`${API_ROUTES.ACCOUNT.REGISTER}`, data, {
      headers: { 'Content-Type': 'application/json', accept: '*/*' },
    });
    return response.data;
  } catch (error: any) {
    const backendErrors = error.response?.data;
    if (Array.isArray(backendErrors) && backendErrors.length > 0) {
      const firstError = backendErrors[0];
      if (firstError.code === 'DuplicateUserName') {
        throw new Error("Email already in use. Try with another email.");
      }
    }
    throw new Error('Error en el registro');
  }
};

// Google Login function - no necesita userType
const googleLogin = async (token: string): Promise<LoginResponse> => {
  try {
    const response = await xervixApi.post<LoginResponse>(`${API_ROUTES.ACCOUNT.GOOGLE_LOGIN}`, { token }, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.data.message === "An error occurred while processing your Google login.") {
      throw new Error('Hay un problema con tu cuenta, solicita al administrador que revise el caso.');
    }
    throw error;
  }
};

const facebookLogin = async (token: string): Promise<LoginResponse> => {
  try {
    const response = await xervixApi.post<LoginResponse>(`${API_ROUTES.ACCOUNT.FACEBOOK_LOGIN}`, { token }, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

// Google Register function - mantiene userType
const googleRegister = async (token: string, userType: string): Promise<LoginResponse> => {
  try {
    const response = await xervixApi.post<LoginResponse>(`${API_ROUTES.ACCOUNT.GOOGLE_REGISTER}`, { token, userType }, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

// Reset password function
const resetPassword = async (email: string): Promise<void> => {
  try {
    const response = await xervixApi.post(`${API_ROUTES.ACCOUNT.RESET_PASSWORD}`, { email }, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      throw new Error('Email not found');
    }
    throw error;
  }
};

// Verify code function
const verifyCode = async (code: string, emailOrPhone: string): Promise<void> => {
  try {
    const response = await xervixApi.post(`${API_ROUTES.ACCOUNT.VERIFY_CODE}`, { code, emailOrPhone }, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 400) {
      //throw new Error('Invalid code');
      throw error;
    }
    throw error;
  }
};

// Resend verification code function
const resendVerificationCode = async (): Promise<void> => {
  try {
    const response = await xervixApi.post(`${API_ROUTES.ACCOUNT.RESEND_VERIFICATION_CODE_EMAIL}`, {}, {
      headers: { accept: '*/*' },
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

// Refresh token function
const refreshToken = async (refreshToken: string): Promise<LoginResponse> => {
  try {
    const response = await xervixApi.post<LoginResponse>(`${API_ROUTES.ACCOUNT.REFRESH_TOKEN}`, { refreshToken }, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export default {
  login,
  register,
  facebookLogin,
  googleLogin,
  googleRegister,
  resetPassword,
  verifyCode,
  resendVerificationCode,
  refreshToken
};
