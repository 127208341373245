// src/components/DetalleServicio.tsx
import React, { useState } from "react";
import { ServiceRequest } from "@/types/request.types";
import { formatDate } from "@/helpers/date";
import useServiceRequestStore from "@/store/useServiceRequestStore";

interface DetailServiceProps {
  servicio: ServiceRequest;
  onBack: () => void;
}

const DetailService: React.FC<DetailServiceProps> = ({ servicio, onBack }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { getRequestsByStatus } = useServiceRequestStore();
  const { cancelService } = useServiceRequestStore();

  const handleCancelService = async () => {
    if (window.confirm('¿Estás seguro de que deseas cancelar este servicio?')) {
      try {
        await cancelService(servicio.serviceRequestId);
        onBack();
      } catch (error) {
        console.error('Error al cancelar el servicio:', error);
      }
    }
  };

  /* const handleCancelService = async () => {
    if (window.confirm('¿Estás seguro de que deseas cancelar este servicio?')) {
      setIsLoading(true);
      try {
        await getRequestsByStatus(servicio.serviceRequestId, "3");
        onBack();
      } catch (error) {
        console.error('Error al cancelar el servicio:', error);
      } finally {
        setIsLoading(false);
      }
    }
  }; */
  return (
    <section>
      <button 
        onClick={onBack}
        className="flex items-center gap-2 mb-4 text-cTextSecondary hover:text-cPrimary"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>
        Volver
      </button>

      <div className="bg-cBackgroundSecondary dark:bg-dark-cBackgroundSecondary rounded-lg p-6">
        <div className="flex gap-6">
          <img 
            src={servicio.category.urlImage} 
            alt={servicio.category.name} 
            className="w-48 h-48 rounded-lg object-cover"
          />
          <div className="flex flex-col gap-4">
            <h2 className="text-2xl font-semibold text-cText dark:text-dark-cText">
              {servicio.category.name}
            </h2>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-cTextSecondary dark:text-dark-cTextSecondary">
                  Servicios solicitados:
                </p>
                {servicio.services.map((service) => (
                  <p key={service.serviceId} className="text-cText dark:text-dark-cText">
                    {service.name}
                  </p>
                ))}
              </div>
              <div>
                <p className="text-sm text-cTextSecondary dark:text-dark-cTextSecondary">
                  Fecha de solicitud:
                </p>
                <p className="text-cText dark:text-dark-cText">
                  {formatDate(servicio.createdAt)}
                </p>
              </div>
              <div>
                <p className="text-sm text-cTextSecondary dark:text-dark-cTextSecondary">
                  Dirección:
                </p>
                <p className="text-cText dark:text-dark-cText">
                  {servicio.address}
                </p>
              </div>
              <div>
                <p className="text-sm text-cTextSecondary dark:text-dark-cTextSecondary">
                  Estado:
                </p>
                <p className="text-cText dark:text-dark-cText">
                  {Number(servicio.serviceRequestStatus) === 1 ? 'Activo' : 'Inactivo'}
                </p>
              </div>
            </div>
            {servicio.description && (
              <div>
                <p className="text-sm text-cTextSecondary dark:text-dark-cTextSecondary">
                  Descripción:
                </p>
                <p className="text-cText dark:text-dark-cText">
                  {servicio.description}
                </p>
              </div>
            )}
            {Number(servicio.serviceRequestStatus) === 1 && (
          <div className="mt-6">
            <button
              onClick={handleCancelService}
              disabled={isLoading}
              className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 disabled:opacity-50"
            >
              {isLoading ? 'Cancelando...' : 'Cancelar Servicio'}
            </button>
          </div>
        )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DetailService;
