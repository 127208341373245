// src/components/templates/MainPanel.tsx
import React, { useState } from "react";
import { RequestListProfessional } from "@/components/molecules/RequestListProfessional";
import { RequestDetailsProfessional } from "@/components/molecules/RequestDetailsProfessional";
import { ProposalForm } from "@/components/molecules/ProposalForm";
import { ServiceRequest } from "@/types/request.types";

export const MainPanelProposal: React.FC = () => {

  const [selectedRequest, setSelectedRequest] = useState<ServiceRequest | null>(null);

  const handleRequestSelect = (request: ServiceRequest) => {
    setSelectedRequest(request);
  };

  return (
    <div className="grid grid-cols-3 gap-4">
      {/* Columna 1: Lista de solicitudes */}
      <div className="col-span-1 bg-gray-100">
        <RequestListProfessional onSelectRequest={handleRequestSelect} />
      </div>

      {/* Columna 2: Detalles de la solicitud */}
      <div className="col-span-1">
        <RequestDetailsProfessional selectedRequest={selectedRequest} />
      </div>

      {/* Columna 3: Formulario de propuesta */}
      <div className="col-span-1 bg-white">
        <ProposalForm selectedRequest={selectedRequest}/>
      </div>
    </div>
  );
};
