import React from "react";
import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/outline";
import Button from '../atoms/Button';
import { useTranslation } from "react-i18next";

interface SearchFieldProps {
  searchTerm: string;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchClick: () => void;
  placeholder: string;
}

const SearchField: React.FC<SearchFieldProps> = ({
  searchTerm,
  onSearchChange,
  onSearchClick,
  placeholder,
}) => {
  const { t } = useTranslation();

  return (
    <div className="text-center mt-4">
      <div className="relative max-w-md mx-auto">
        <input
          type="text"
          placeholder={placeholder}
          value={searchTerm}
          onChange={onSearchChange}
          className="text-[13px] xl:text-[16px] px-4 py-2 border rounded-full w-full dark:bg-dark-cBackground dark:text-dark-cText pr-10 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent"
        />
        <Button
          onClick={onSearchClick}
          variant="outlined"
          size="small"
          className="absolute right-3 top-1/2 transform -translate-y-1/2 !p-0 !border-0 hover:bg-transparent"
        >
          <MagnifyingGlassCircleIcon className="fill-bgBrandLight stroke-bgBrand dark:fill-transparent size-8" />
        </Button>
      </div>
    </div>
  );
};

export default SearchField;
