import React, {useState} from "react";
import { useLocation } from 'react-router-dom';
import { Proposal, ProposalState } from "@/types/proposal.types";
import { ProfessionalInfo } from "@/types/profile.types";
import useProposalStore from "@/store/useProposalStore";
import { useNavigate } from 'react-router-dom';
import { toast, Id } from 'react-toastify';


interface ProposalDetailSectionProps {
  proposal?: Proposal;
}

interface LocationState {
  proposal: Proposal;
  professionalInfo: ProfessionalInfo;
}

const ProposalDetailSection: React.FC<ProposalDetailSectionProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { proposal, professionalInfo } = location.state as LocationState;
  const { updateStatus, isLoading } = useProposalStore();
  const [toastId, setToastId] = useState<Id | null>(null);

  const handleApprove = async () => {
    if (proposal?.proposalId) {
      const id = toast.loading('Procesando aprobación...', {
        position: "top-right",
      });
      setToastId(id);

      try {
        await updateStatus(proposal.proposalId, ProposalState.Approved);
        toast.update(id, {
          render: "Propuesta aprobada exitosamente",
          type: "success",
          isLoading: false,
          autoClose: 5000
        });
        navigate('/requests');
      } catch (error) {
        toast.update(id, {
          render: "Error al aprobar la propuesta",
          type: "error",
          isLoading: false,
          autoClose: 5000
        });
        console.error('Error:', error);
      }
    }
  };

  return (
    <div className="flex w-full h-screen ">
      {/* Sidebar */}
      <aside className="w-1/5 h-3/4 m-10 bg-white shadow-md p-6">
        <div className="mb-6">
          <h2 className="text-teal-600 font-bold text-lg line-clamp-2">
            PROPUESTA {proposal?.proposalId || 'N/A'}
          </h2>
        </div>
        <nav className="space-y-4">
          <a
            href="#"
            className="flex items-center text-cTextSecondary hover:text-teal-600 transition"
          >
            <div className="w-6 h-6 bg-gray-200 rounded-full mr-3 flex items-center justify-center text-gray-600">
              📊
            </div>
            Propuesta
          </a>
          <a
            href="#"
            className="flex items-center text-cTextSecondary hover:text-teal-600 transition"
          >
            <div className="w-6 h-6 bg-gray-200 rounded-full mr-3 flex items-center justify-center text-gray-600">
              👤
            </div>
            Perfil
          </a>
          <a
            href="#"
            className="flex items-center text-cTextSecondary hover:text-teal-600 transition"
          >
            <div className="w-6 h-6 bg-gray-200 rounded-full mr-3 flex items-center justify-center text-gray-600">
              💬
            </div>
            Mensajes
          </a>
          <a
            href="#"
            className="flex items-center text-cTextSecondary hover:text-teal-600 transition"
          >
            <div className="w-6 h-6 bg-gray-200 rounded-full mr-3 flex items-center justify-center text-gray-600">
              🛒
            </div>
            MarketPlace
          </a>
          <a
            href="#"
            className="flex items-center text-cTextSecondary hover:text-teal-600 transition"
          >
            <div className="w-6 h-6 bg-gray-200 rounded-full mr-3 flex items-center justify-center text-gray-600">
              ❤️
            </div>
            Opiniones
          </a>
        </nav>
        <div className="mt-8">
          <div className="flex items-center p-4 bg-gray-100 rounded-lg">
            <img
              src={
                professionalInfo?.companyImage || 
                `https://eu.ui-avatars.com/api/?name=${professionalInfo?.name || 'User'}+${professionalInfo?.lastNames || ''}&size=250`
              }
              alt="Cambiedge pets Mascotas"
              className="w-10 h-10 rounded-full mr-3"
            />
            <div>
              <h3 className="text-gray-800 font-medium">Cambiedge pets</h3>
              <p className="text-sm text-gray-500">Mascotas</p>
            </div>
          </div>
        </div>
      </aside>

      {/* Main Content */}
      <main className="flex-1 p-8">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-gray-800 text-xl font-semibold">
            Nuestra propuesta
          </h1>
          <div className="text-right">
            <p className="text-red-600 font-semibold text-xl">Precio</p>
            <p className="text-2xl font-bold text-red-600">
              {proposal?.proposalValue} COP
            </p>
            <button
            onClick={handleApprove}
            disabled={isLoading}
            className="mt-2 px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition"
            >
              {isLoading ? 'Procesando...' : 'APROBAR'}
            </button>
          </div>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <p className="text-gray-700 mb-4">
            {proposal?.descriptionProposet}
          </p>
        </div>
      </main>
    </div>
  );
};

export default ProposalDetailSection;
