import { xervixApi } from "@/config/axios.config";
import { API_ROUTES } from "@/constants/apiRoutes";
import { UserInfo, ProfessionalInfo } from "@/types/profile.types";

const fetchProfessionalInfo = async (
  professionalId: string
): Promise<ProfessionalInfo> => {
  const response = await xervixApi.get(
    API_ROUTES.PROFILE.GET_PROFESSIONAL_INFO(professionalId)
  );
  return response.data;
};

const fetchUserTypeInfo = async (
  /* userType: string */
): Promise<UserInfo> => {
  try {
    const userType = "USER";
    const response = await xervixApi.get<UserInfo>(
      `${API_ROUTES.PROFILE.GET_BY_USER_TYPE_INFO(userType)}`
    );
    return response.data;
  } catch (error: any) {
    throw new Error("Error al obtener el perfil");
  }
};

const fetchProfessionalTypeInfo = async (
  /* userType?: string */
): Promise<ProfessionalInfo> => {
  try {
    const userType = "PROFESSIONAL";
    const response = await xervixApi.get<ProfessionalInfo>(
      `${API_ROUTES.PROFILE.GET_BY_USER_TYPE_INFO(userType)}`
    );
    return response.data;
  } catch (error: any) {
    throw new Error("Error al obtener el perfil");
  }
};

export default {
  fetchProfessionalInfo,
  fetchUserTypeInfo,
  fetchProfessionalTypeInfo,
};
