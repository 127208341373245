import React from 'react';
import { useTranslation } from 'react-i18next';
import CategoriesGrid from '../components/organisms/landing-page/CategoriesGrid';


const CategoriesPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center pb-6 px-4">
      <h1 className="text-[22px] font-semibold text-cText dark:text-dark-cText mb-6">{t("categoriesPage.title")}</h1>
      <CategoriesGrid />
    </div>
  );
};

export default CategoriesPage;