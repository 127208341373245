// src/components/molecules/RequestListProfessional.tsx
import React, { useEffect, useState } from "react";
import useServiceRequestStore from "@/store/useServiceRequestStore";
import { PeopleRequestCard } from "./PeopleRequestCard";
import { ServiceRequest, RequestFilters } from "@/types/request.types";
import useProfileStore from "@/store/useProfileStore";
import { formatTimeAgo } from '@/helpers/date';
import { calculatePaginationRange } from '@/helpers/calculatePaginationRange';


interface RequestListProfessionalProps {
  onSelectRequest: (request: ServiceRequest) => void;
}

export const RequestListProfessional: React.FC<
  RequestListProfessionalProps
> = ({ onSelectRequest }) => {
  const { getFilteredRequests, setFilters, filters, response, isLoading } =
    useServiceRequestStore();
  const [selectedRequestId, setSelectedRequestId] = useState<string | null>(
    null
  );
  const { professional, getProfessionalTypeInfo } = useProfileStore();

  useEffect(() => {
    getProfessionalTypeInfo();
  }, []);

  useEffect(() => {
    if (professional?.category.categoryId) {
      setFilters({
        categoryId: professional.category.categoryId,
        pageNumber: 1,
        pageSize: 20,
      });
    }
  }, [professional]);

  useEffect(() => {
    getFilteredRequests();
  }, [filters]);

  const handleFilterChange = (newFilters: Partial<RequestFilters>) => {
    setFilters({ ...newFilters, pageNumber: 1 }); // Reset to first page on filter change
  };

  const selectRequest = (request: ServiceRequest) => {
    setSelectedRequestId(request.serviceRequestId);
    onSelectRequest(request);
  };

  return (
    <div className="p-4 space-y-4 bg-cBackground dark:bg-dark-cBackground rounded-lg shadow-md">
      <h3 className="font-semibold text-center text-cText text-2xl mb-4">
        Mis Solicitudes
      </h3>

      {isLoading ? (
        <div>Cargando...</div>
      ) : response?.items && response.items.length > 0 ? (
        response.items.map((request: ServiceRequest) => (
          <PeopleRequestCard
            key={request.serviceRequestId}
            name={`${request.customer?.names || ""} ${
              request.customer?.lastNames || ""
            }`.trim()}
            service={request.services[0]?.name || ""}
            timeAgo={formatTimeAgo(request.requestDate)}
            urgent={request.priority === 1}
            onClick={() => selectRequest(request)}
            isSelected={selectedRequestId === request.serviceRequestId}
          />
        ))
      ) : (
        <div>No hay solicitudes</div>
      )}

      {/* Pagination controls */}
      {response && (
        <div className="flex items-center justify-between mt-6 px-4 py-3 bg-white border rounded-lg">
          <div className="flex items-center text-sm text-gray-500">
            <span>
              Mostrando{" "}
              <span className="font-medium">
                {((filters.pageNumber || 1) - 1) * (filters.pageSize || 10) + 1}
              </span>{" "}
              a{" "}
              <span className="font-medium">
                {Math.min(
                  (filters.pageNumber || 1) * (filters.pageSize || 10),
                  response.totalItems
                )}
              </span>{" "}
              de <span className="font-medium">{response.totalItems}</span>{" "}
              resultados
            </span>
          </div>

          <div className="flex items-center gap-2">
            <button
              onClick={() =>
                setFilters({ pageNumber: (filters.pageNumber || 1) - 1 })
              }
              disabled={filters.pageNumber === 1}
              className={`px-4 py-2 text-sm font-medium rounded-md ${
                filters.pageNumber === 1
                  ? "text-gray-400 bg-gray-100 cursor-not-allowed"
                  : "text-gray-700 bg-white hover:bg-gray-50 border"
              }`}
            >
              <span className="sr-only">Página anterior</span>
              Anterior
            </button>

            <span className="text-sm text-gray-500">
              Página{" "}
              <span className="font-medium">{filters.pageNumber || 1}</span> de{" "}
              <span className="font-medium">
                {Math.ceil(response.totalItems / (filters.pageSize || 10))}
              </span>
            </span>

            <button
              onClick={() =>
                setFilters({ pageNumber: (filters.pageNumber || 1) + 1 })
              }
              disabled={
                (filters.pageNumber || 1) * (filters.pageSize || 10) >=
                response.totalItems
              }
              className={`px-4 py-2 text-sm font-medium rounded-md ${
                (filters.pageNumber || 1) * (filters.pageSize || 10) >=
                response.totalItems
                  ? "text-gray-400 bg-gray-100 cursor-not-allowed"
                  : "text-gray-700 bg-white hover:bg-gray-50 border"
              }`}
            >
              <span className="sr-only">Página siguiente</span>
              Siguiente
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
