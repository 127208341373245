import React, { useState } from 'react';
import { useServiceRequestEvaluation } from '@/store/useEvaluationStore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EvaluationForm: React.FC = () => {
    const [rating, setRating] = useState<number>(5);
    const [hover, setHover] = useState<number | null>(null);
    const [comment, setComment] = useState<string>('');
    const { isLoading, error, submitEvaluation } = useServiceRequestEvaluation();
    const userName = "Usuario"; // Replace with actual user name from context/props

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            await toast.promise(
                submitEvaluation({
                    serviceRequestId: '4119c837-e16e-4e05-b135-8dbc8aff4a32',
                    rating,
                    comment,
                }),
                {
                    pending: 'Enviando evaluación...',
                    success: '¡Gracias por tu evaluación! 👍',
                    error: 'Error al enviar la evaluación 🤯'
                }
            );
            setComment('');
            setRating(5);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-8 p-6">
            
            {/* Left Column */}
            <div className="space-y-6">
                <div className="space-y-2">
                    <h2 className="text-2xl text-cText dark:text-dark-cText font-semibold">¡Hola {userName}!</h2>
                    <p className="text-lg text-cTextSecondary dark:text-dark-cTextSecondary leading-relaxed">
                        Esperamos que tanto tú como tu mascota hayan tenido una excelente experiencia con nosotros.
                        Nos encantaría saber qué te pareció el servicio y cómo podemos seguir mejorando
                    </p>
                </div>

                <div className="space-y-4">
                    <p className="text-cTextSecondary dark:text-dark-cTextSecondary text-lg leading-relaxed">
                        ¿Te animas dejarnos una calificación rápida de la empresa prestadora de tu servicio?
                    </p>
                    <div className="flex gap-2">
                        {[...Array(5)].map((_, index) => {
                            const ratingValue = index + 1;
                            return (
                                <button
                                    type="button"
                                    key={ratingValue}
                                    onClick={() => setRating(ratingValue)}
                                    onMouseEnter={() => setHover(ratingValue)}
                                    onMouseLeave={() => setHover(null)}
                                    className="focus:outline-none"
                                >
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        viewBox="0 0 24 24" 
                                        fill="currentColor" 
                                        className={`size-12 transition-colors ${
                                            (hover !== null ? ratingValue <= hover : ratingValue <= rating)
                                                ? 'text-bgBrand'
                                                : 'text-cTextSecondary/50'
                                        }`}
                                    >
                                        <path 
                                            fillRule="evenodd" 
                                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" 
                                            clipRule="evenodd" 
                                        />
                                    </svg>
                                </button>
                            );
                        })}
                    </div>
                </div>
            </div>

            {/* Right Column */}
            <div className="space-y-6">
                <h3 className="text-xl font-medium text-cText">¿Quieres dar tu opinión?</h3>
                <textarea
                    id="comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    className="w-full h-40 p-4 border border-gray-300 rounded-lg resize-none focus:ring-2 focus:ring-pink-500 focus:border-transparent"
                    placeholder="Escribe tu opinión aquí..."
                />
                <button
                    type="submit"
                    disabled={isLoading}
                    className="w-full py-3 px-4 bg-bgBrand text-white rounded-lg hover:bg-bgBrandHover transition-colors disabled:opacity-50"
                >
                    {isLoading ? 'Enviando...' : 'ENVIAR'}
                </button>
                {error && <p className="text-red-500">{error}</p>}
            </div>
        </form>
        </>
    );
};

export default EvaluationForm;