import React, { useState } from "react";
import Button from "@/components/atoms/Button";
import { ServiceRequest } from "@/types/request.types";
import useProposalStore from "@/store/useProposalStore";

interface ProposalFormProps {
  selectedRequest: ServiceRequest | null;
}

export const ProposalForm: React.FC<ProposalFormProps> = ({ selectedRequest }) => {
  const { submitProposal, isLoading, error } = useProposalStore();
  const [formData, setFormData] = useState({
    proposalValue: 0,
    daysToComplete: 0,
    descriptionProposet: ''
  });

  if (!selectedRequest) {
    return (
      <div className="p-4 bg-cBackground h-full rounded-lg shadow-lg text-gray-500">
        Debes seleccionar una solicitud para generar una propuesta
      </div>
    );
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await submitProposal({
      serviceRequestId: selectedRequest.serviceRequestId,
      ...formData
    });

    setFormData({
      proposalValue: 0,
      daysToComplete: 0,
      descriptionProposet: ''
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="p-4 border rounded">
      <h3 className="font-bold mb-4">¡Haz tu propuesta!</h3>
      
      <div className="mb-4">
        <label className="block mb-2">Valor de la propuesta</label>
        <input
          type="number"
          name="proposalValue"
          value={formData.proposalValue}
          onChange={handleChange}
          className="w-full p-2 border rounded"
          required
        />
      </div>

      <div className="mb-4">
        <label className="block mb-2">Días para completar</label>
        <input
          type="number"
          name="daysToComplete"
          value={formData.daysToComplete}
          onChange={handleChange}
          className="w-full p-2 border rounded"
          required
        />
      </div>

      <div className="mb-4">
        <label className="block mb-2">Descripción de la propuesta</label>
        <textarea
          name="descriptionProposet"
          value={formData.descriptionProposet}
          onChange={handleChange}
          className="w-full p-2 border rounded"
          rows={4}
          required
        />
      </div>

      {error && <div className="text-red-500 mb-4">{error}</div>}

      <Button
        type="submit"
        disabled={isLoading}
      >
        {isLoading ? 'Enviando...' : 'Enviar Propuesta'}
      </Button>
    </form>
  );
};