// src/components/serviceCard.tsx
import React, { useEffect } from "react";
import { ServiceRequest, ServiceWithName } from "@/types/request.types";
import { formatDate } from '@/helpers/date';
import useProfileStore from "@/store/useProfileStore";

interface ServiceCardProps {
  service: ServiceRequest;
  onClick: () => void;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ service, onClick }) => {
  const { professional, getProfessionalInfo } = useProfileStore();

  useEffect(() => {
    getProfessionalInfo(service.propofesionalId);
  }, [getProfessionalInfo, service.propofesionalId]);

  return (
    <div
      className="flex flex-col w-full xs:max-w-xs xl:max-w-lg gap-2 bg-white p-4 rounded-2xl shadow-md cursor-pointer hover:shadow-lg transition"
      onClick={onClick}
    >
      <img src={service.category.urlImage} alt={service.category.name} className="rounded-t-lg h-32 w-full object-cover" />
      <div className="flex flex-col gap-4 mt-4">
        <h4 className=" text-cText dark:text-dark-cText font-medium text-lg">{service.category.name}</h4>
        <div className="flex flex-row justify-between items-center">
          {service.services.map((service: ServiceWithName) => (
            <p className="text-cTextSecondary dark:text-dark-cTextSecondary text-sm">{service.name}</p>
          ))}
          <p className="text-cTextSecondary dark:text-dark-cTextSecondary text-sm ">{  formatDate(service.createdAt)}</p>
        </div>
        <p className="text-cTextSecondary dark:text-dark-cTextSecondary text-sm">{professional?.name}</p>
      </div>
    </div>
  );
};

export default ServiceCard;
