// @vendors
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// @store
import useOnboardingStore from '../../store/useOnboardingStore';
import { useSpinnerStore } from '@/store/useSpinnerStore';

// @config
import routesConfig from '../../config/routes.config';

const SuccessOnboardingPage: React.FC = () => {
  const { t } = useTranslation(); // Para las traducciones
  const navigate = useNavigate(); // Para la navegación
  const { completeOnboarding } = useOnboardingStore(); // Asumiendo que esto está en tu store
  const { openSpinner } = useSpinnerStore();

  // Obtener los datos de autenticación almacenados
  const authData = localStorage.getItem('authResponse');
  const parsedAuthData = authData ? JSON.parse(authData) : null;

  // Completar el onboarding
  const handleCompleteOnboarding = async () => {
    openSpinner(true);
    try {
      await completeOnboarding(); // Llamamos al método del store
      if (parsedAuthData) {
        // Asegurarse que el token sigue siendo válido
        if (parsedAuthData.accessToken) navigate(routesConfig.home);
        else navigate(routesConfig.login);
      } else navigate(routesConfig.login);
      openSpinner(false);
    } catch (error: any) {
      openSpinner(false);
      toast.error(t('onboarding.errorMessage'));
    } finally {
      openSpinner(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-white p-6">
      {/* Imagen de fondo */}
      <div className="absolute inset-0">
        <img
          src="/assets/img/success_background.png"
          alt="Success Background"
          className="w-full h-full object-cover"
        />
      </div>

      <div className="relative z-10 flex flex-col items-center">
        {/* Imagen de éxito */}
        <img
          src="/assets/icons/brand/success.png"
          alt="Success Icon"
          className="w-68 h-48"
        />
        <h1 className="text-3xl font-bold text-black mt-6 text-center">
          {t('onboarding.successTitle')}
        </h1>
        <p className="text-lg text-gray-700 mt-4 text-center">
          {t('onboarding.successDescription')}
        </p>

        {/* Botón de aceptar */}
        <div className="mt-8">
          <button
            onClick={handleCompleteOnboarding}
            className="bg-red-500 text-white px-12 py-3 rounded-full"
          >
            {t('onboarding.acceptButton')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessOnboardingPage;
