import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserRole } from '../../types/auth.types';
import { useAuth } from "../../hooks/useAuth";
import routesConfig from "../../config/routes.config";

interface ProtectedRouteProps {
    allowedRoles: UserRole[];
    redirectTo?: string;
}

export const ProtectedRoute = ({
    allowedRoles,
    redirectTo = routesConfig.home,
}: ProtectedRouteProps) => {
    const { checkAccess, isLoading } = useAuth();
    //console.log(checkAccess(allowedRoles));
    if (isLoading) {
        return <div>Cargando...</div>;
    }

    return checkAccess(allowedRoles) ? <Outlet /> : <Navigate to={redirectTo} replace />;
};