import React from 'react';
import { useTranslation } from 'react-i18next';
import { Service } from '../../../types/service.types';
import { useNavigate } from 'react-router-dom';
import routesConfig from '../../../config/routes.config';
import { ServiceCard } from '../../molecules/TopServiceCard';
import useServiceStore from '../../../store/useServiceStore';


const TopServicesHero: React.FC<{ onButtonClick: () => void }> = ({ onButtonClick }) => {
  const { services, fetchTopServices, isLoading } = useServiceStore();
  const { t } = useTranslation();
  const navigate = useNavigate();

  React.useEffect(() => {
    fetchTopServices();
  }, []);

  const handleRequestClick = (service: any) => {
    console.log("Enviar Solicitud para:", service.name);
    onButtonClick();
  };

  return (
    <section className="py-16">
      <h2 className="text-[16px] lg:text-[20px] font-semibold xl:text-[24px] xl:font-bond mb-6 text-center">{t("landingPage.topServicesHero.title")}</h2>
      <div className="h-1 lg:h-10"></div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-10 lg:gap-20 px-2 md:px-12 lg:px-24 text-center">
        {Array.isArray(services) && services.map((service, index) => (
          <ServiceCard key={index} service={service} onRequestClick={onButtonClick} />
        ))}
      </div>
    </section>
  );
}

export default TopServicesHero;