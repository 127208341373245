// src/components/organisms/RequestDetails.tsx
import React from "react";
import { ServiceRequest } from "@/types/request.types";
import { formatTime } from '@/helpers/date';

interface RequestDetailsProfessionalProps {
  selectedRequest: ServiceRequest | null;
}

export const RequestDetailsProfessional: React.FC<{
  selectedRequest: RequestDetailsProfessionalProps["selectedRequest"];
}> = ({ selectedRequest }) => {
  if (!selectedRequest) {
    return (
      <div className="p-4 bg-cBackground h-full rounded-lg shadow-lg text-cTextSecondary">
        Selecciona una solicitud para ver los detalles
      </div>
    );
  }

  return (
    <div className="p-6 border rounded-lg bg-cBackground shadow-md">
      {/* Título y ID */}
      <h3 className="text-lg font-semibold text-cText mb-2">
        Solicitud {selectedRequest.serviceRequestNumber}
      </h3>
      <p className="text-sm text-cTextSecondary mb-4">
        {selectedRequest.services[0]?.name || "Servicio no especificado"}
      </p>

      {/* Nombre del cliente */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-cTextSecondary">
          Nombre de quien solicita el servicio
        </label>
        <p className="border rounded-md p-2 bg-cBackgroundSecondary text-cText">
          {selectedRequest.customer.names} {selectedRequest.customer.lastNames}
        </p>
      </div>

      {/* Fecha y hora del servicio */}
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block text-sm font-medium text-cTextSecondary">
            Fecha del servicio
          </label>
          <p className="border rounded-md p-2 bg-cBackgroundSecondary text-cText">
            {new Date(selectedRequest.requestDate).toLocaleDateString()}
          </p>
        </div>
        <div>
          <label className="block text-sm font-medium text-cTextSecondary">
            Hora del servicio
          </label>
          <p className="border rounded-md p-2 bg-cBackgroundSecondary text-cText">
            {formatTime(selectedRequest.requestDate) || "No especificada"}
          </p>
        </div>
      </div>

      {/* Imágenes o texto relacionado */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-cTextSecondary">
          Imágenes
        </label>
        {/* {selectedRequest.images && selectedRequest.images.length > 0 ? (
          <img
            src={selectedRequest.images[0]}
            alt="Imagen relacionada con el servicio"
            className="w-full h-40 object-cover rounded-md border"
          />
        ) : ( */}
          <div className="border rounded-md p-4 bg-cBackgroundSecondary flex justify-center items-center text-cTextSecondary">
            No hay imágenes disponibles para esta solicitud.
          </div>
        {/* )} */}
      </div>

      {/* Descripción del servicio */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-cTextSecondary">
          Descripción del servicio
        </label>
        <p className="border rounded-md p-2 bg-cBackgroundSecondary text-cText">
          {selectedRequest.description || "Sin descripción"}
        </p>
      </div>

      {/* Servicio en domicilio */}
      {/* <div className="mb-4 flex items-center">
        <label className="block text-sm font-medium text-cTextSecondary mr-4">
          Requiere el servicio en mi domicilio
        </label>
        <input
          type="checkbox"
          checked={selectedRequest.isHomeService}
          disabled
          className="w-4 h-4 text-bgBrand border-cBorder rounded focus:ring-bgBrandHover"
        />
      </div> */}

      {/* Dirección del domicilio (si aplica) */}
      {/* {selectedRequest.isHomeService && (
        <div className="mb-4">
          <label className="block text-sm font-medium text-cTextSecondary">
            Dirección de tu domicilio
          </label>
          <p className="border rounded-md p-2 bg-cBackgroundSecondary text-cText">
            {selectedRequest.address}
          </p>
        </div>
      )} */}
    </div>
  );
};
