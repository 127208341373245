import React, { memo } from "react";
import { CategoryHierarchy } from "../../types/category.types";
import { Card } from "../atoms/Card";

interface CategoryCardProps {
  category: CategoryHierarchy;
  isSelected?: boolean;
  totalServices?: number;
  isNavigable?: boolean;
  loading?: boolean;
  handleClick: (category: CategoryHierarchy) => void;
}

const CategorySubServices: React.FC<{
  subCategories: CategoryHierarchy["subCategories"];
}> = memo(({ subCategories }) => (
  <ul className="mt-2">
    {subCategories
      .flat()
      .slice(0, 3)
      .map((service, index) => (
        <li
          key={index}
          className="w-full text-cTextSecondary dark:text-dark-cTextSecondary text-[12px] xl:text-[16px] leading-[20px] xl:leading-[30px] truncate"
        >
          - {service.name}
        </li>
      ))}
  </ul>
));

export const CategoryCard: React.FC<CategoryCardProps> = ({
  category,
  isSelected,
  totalServices,
  isNavigable = true,
  handleClick,
}) => {

  const textClasses = isSelected ? "text-textOnBrand" : "";

  return (
    <div
      key={category.categoryId}
      className={`relative flex-none w-[216px] xl:w-[280px] cursor-pointer select-none`}
      onClick={() => handleClick(category)}
    >
      <Card
        variant={isSelected ? "selected" : "default"}
        hoverable
        className="p-[14px] xl:p-4 bg-bgBrand "
      >
        <img
          src={category.urlImage}
          alt={category.name}
          className="w-full h-[180px] xl:h-64 object-cover rounded-xl xl:rounded-3xl xl:p-1 "
        />
        <div className="pt-[10px] xl:p-4">
          <div className="flex  xl:flex-row items-start xl:items-center justify-between gap-[4px] xl:gap-2">
            <h3 className={`font-semibold text-[14px] xl:text-[18px] text-cText dark:text-dark-cText ${textClasses}`}>
              {category.name}
            </h3>
            <p className={`text-[12px] xl:text-[16px] text-cTextSecondary dark:text-dark-cTextSecondary ${textClasses} whitespace-nowrap`}>
              {totalServices} Servicios
            </p>
          </div>
          {isNavigable && category.subCategories && (
            <CategorySubServices subCategories={category.subCategories} />
          )}
        </div>
      </Card>
    </div>
  );
};
