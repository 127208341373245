import { useState, useEffect } from "react";
import { Switch } from "@headlessui/react";
import { CreatePostRequest } from "@/types/post.types";
import usePostStore from "@/store/usePostStore";
import useProfileStore from "@/store/useProfileStore";
import useCategoryStore from '@/store/useCategoryStore';
import { CategoryHierarchy } from '@/types/category.types';
import { toast } from "react-toastify";

import {
  CameraIcon,
  ArrowUpTrayIcon,
  XMarkIcon,
  HeartIcon,
  BookmarkIcon,
  ShareIcon,
  ArrowLeftIcon,
} from "@heroicons/react/24/solid";

const categories = [
  "Friends",
  "Me",
  "Family",
  "Concerts",
  "Trips",
  "Food",
  "Bands",
  "Work",
  "My cat",
  "Architecture",
];

export default function CreatePostPage() {
  const [title, setTitle] = useState("Aquí va el título de tu publicación");
  const [description, setDescription] = useState(
    "Aquí va la descripción de tu publicación"
  );
  const [hashtags, setHashtags] = useState<string>(
    "#hashtag #de #tu #publicación"
  );
  const [allowComments, setAllowComments] = useState(false);
  const [allowSound, setAllowSound] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [categories, setCategories] = useState<CategoryHierarchy[]>([]);
    const { categoryHierarchy, fetchCategoriesHierarchy, isLoading } = useCategoryStore();
  const { professional } = useProfileStore();
  const companyImage =
    professional?.companyImage ||
    `https://eu.ui-avatars.com/api/?name=${professional?.name || "User"}+${
      professional?.lastNames || ""
    }&size=250`;

    useEffect(() => {
        fetchCategoriesHierarchy();
      }, [fetchCategoriesHierarchy]);
      
      useEffect(() => {
        if (categoryHierarchy) {
          setCategories(categoryHierarchy);
        }
      }, [categoryHierarchy]);

  const createPost = usePostStore((state) => state.createPost);

  const handleHashtagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHashtags(e.target.value);
  };

  // In your submit handler:
  const handleSubmit = async () => {
    const postData: CreatePostRequest = {
      title,
      description,
      categoryId: selectedCategory,
      hashtags: hashtags.split(" "), // Convert space-separated hashtags to array
      includeAudio: allowSound,
    };

    try {
      await createPost(postData);
      toast.success('Post created successfully');
      // Reset form
    setTitle('');
    setDescription('');
    setSelectedCategory('');
    setAllowComments(false);
    setAllowSound(false);
    setSelectedImage(null);
    } catch (error) {
        toast.error('Error creating post');
        console.error('Error creating post:', error);
    }
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
  };

  return (
    <div className="flex w-full p-4 bg-cBackground dark:bg-dark-cBackground">
      {/* Gallery Selection */}
      <div className="w-1/3 p-4">
        <h3 className="text-lg font-semibold text-cText dark:text-dark-cText mb-2">
          Elige una imagen de tu galería
        </h3>
        {!selectedImage ? (
          <div className="grid grid-cols-2 gap-2">
            {categories.map((category) => (
              <label
                key={category.categoryId}
                onClick={() => setSelectedCategory(category.categoryId)}
                className="relative w-full h-24 bg-gray-300 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:bg-gray-400 transition-colors"
              >
                <input
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleImageUpload}
                />
                <ArrowUpTrayIcon className="h-6 w-6 text-white mb-1" />
                <span className="text-white font-medium">{category.name}</span>
              </label>
            ))}
          </div>
        ) : (
          <div className="relative">
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full rounded-lg aspect-square object-cover"
            />
            <button
              onClick={handleRemoveImage}
              className="absolute top-2 right-2 p-1 bg-red-500 rounded-full text-white hover:bg-red-600"
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>
        )}
      </div>

      {/* Form Section */}
      <div className="w-1/3 p-4 space-y-4">
        <h3 className="text-lg font-semibold text-cText dark:text-dark-cText mb-4">
          Nueva Publicación
        </h3>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-cText dark:text-dark-cText">
            Escribe un título
          </label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Ingresa el título de tu publicación"
            className="w-full p-3 border border-cBorder dark:border-dark-cBorder rounded-lg
                 bg-cBackgroundSecondary dark:bg-dark-cBackgroundSecondary
                 text-cText dark:text-dark-cText
                 placeholder-cTextSecondary dark:placeholder-dark-cTextSecondary
                 focus:outline-none focus:ring-2 focus:ring-bgBrand dark:focus:ring-dark-bgBrand"
          />
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-cText dark:text-dark-cText">
            Escribe una descripción
          </label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Ingresa la descripción de tu publicación"
            rows={4}
            className="w-full p-3 border border-cBorder dark:border-dark-cBorder rounded-lg
                 bg-cBackgroundSecondary dark:bg-dark-cBackgroundSecondary
                 text-cText dark:text-dark-cText
                 placeholder-cTextSecondary dark:placeholder-dark-cTextSecondary
                 focus:outline-none focus:ring-2 focus:ring-bgBrand dark:focus:ring-dark-bgBrand"
          />
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-cText dark:text-dark-cText">
            Hashtags
          </label>
          <input
            type="text"
            value={hashtags}
            onChange={handleHashtagChange}
            placeholder="#música #concierto #amigos"
            className="w-full p-3 border border-cBorder dark:border-dark-cBorder rounded-lg
                 bg-cBackgroundSecondary dark:bg-dark-cBackgroundSecondary
                 text-cText dark:text-dark-cText
                 placeholder-cTextSecondary dark:placeholder-dark-cTextSecondary
                 focus:outline-none focus:ring-2 focus:ring-bgBrand dark:focus:ring-dark-bgBrand"
          />
        </div>

        <div className="flex items-center justify-between">
          <label className="text-sm font-medium text-cText dark:text-dark-cText">
            Permitir comentarios
          </label>
          <Switch
            checked={allowComments}
            onChange={setAllowComments}
            className={`${
              allowComments
                ? "bg-bgBrand dark:bg-dark-bgBrand"
                : "bg-bgDisabled dark:bg-dark-bgDisabled"
            } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none`}
          >
            <span
              className={`${
                allowComments ? "translate-x-6" : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
            />
          </Switch>
        </div>

        <div className="flex items-center justify-between">
          <label className="text-sm font-medium text-cText dark:text-dark-cText">
            Permitir audio
          </label>
          <Switch
            checked={allowSound}
            onChange={setAllowSound}
            className={`${
              allowSound
                ? "bg-bgBrand dark:bg-dark-bgBrand"
                : "bg-bgDisabled dark:bg-dark-bgDisabled"
            } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none`}
          >
            <span
              className={`${
                allowSound ? "translate-x-6" : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
            />
          </Switch>
        </div>
      </div>

      {/* Mobile Preview */}
      <div className="w-1/3 p-4">
        <h3 className="text-lg font-semibold text-cText dark:text-dark-cText mb-2">
          Vista previa
        </h3>
        <div className="w-[320px] h-[640px] bg-black rounded-[40px] border-[14px] border-black shadow-lg mx-auto">
          <div className="relative w-full h-full bg-white rounded-[26px] overflow-hidden">
            {/* Top Navigation */}
            <div className="absolute top-0 left-0 right-0 z-10">
              <div className="flex flex-col w-full">
                <div className="flex items-center px-4 pt-2">
                  <button className="p-2">
                    <ArrowLeftIcon className="h-6 w-6 text-white" />
                  </button>
                </div>
                <div className="flex justify-center gap-6 p-4">
                  <button className="text-white/50 font-medium">
                    Categoría
                  </button>
                  <button className="text-white font-medium">Para mí</button>
                  <button className="text-white/50 font-medium">
                    Favoritos
                  </button>
                </div>
              </div>
            </div>

            {selectedImage ? (
              <>
                <img
                  src={selectedImage}
                  alt="Preview"
                  className="w-full h-full object-cover"
                />

                {/* Interaction buttons column */}
                <div className="absolute bottom-20 right-4 flex flex-col items-center gap-4">
                  <div className="flex flex-col items-center">
                    <div className="w-10 h-10 rounded-full overflow-hidden bg-white">
                      <img
                        src={companyImage}
                        alt="Company"
                        className="w-full h-full object-cover"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <button className="hover:scale-110 transition-transform">
                      <HeartIcon className="h-7 w-7 text-white" />
                    </button>
                    <span className="text-white text-xs">15.4K</span>
                  </div>
                  <div className="flex flex-col items-center">
                    <button className="hover:scale-110 transition-transform">
                      <BookmarkIcon className="h-7 w-7 text-white" />
                    </button>
                    <span className="text-white text-xs">2.1K</span>
                  </div>
                  <div className="flex flex-col items-center">
                    <button className="hover:scale-110 transition-transform">
                      <ShareIcon className="h-7 w-7 text-white" />
                    </button>
                    <span className="text-white text-xs">3.8K</span>
                  </div>
                </div>

                {/* Content overlay */}
                <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 to-transparent p-4">
                  <h4 className="text-white font-semibold">{title}</h4>
                  <p className="text-white text-sm mt-1">{description}</p>
                  <p className="text-white/80 text-sm mt-2 font-medium">
                    {selectedCategory}
                  </p>
                </div>
              </>
            ) : (
              <div className="flex items-center justify-center h-full text-gray-400">
                <p>Sube una imagen para ver la vista previa</p>
              </div>
            )}
          </div>
          {/* Action Buttons */}
          <div className="flex justify-between items-center mt-6 px-4">
            <button
              onClick={() => window.history.back()}
              className="px-6 py-2.5 border border-cBorder dark:border-dark-cBorder 
                  text-cText dark:text-dark-cText 
                  rounded-lg hover:bg-cBackgroundSecondary 
                  dark:hover:bg-dark-cBackgroundSecondary 
                  transition-colors"
            >
              CANCELAR
            </button>

            <button
              onClick={handleSubmit}
              className="px-6 py-2.5 bg-bgBrand dark:bg-dark-bgBrand 
                  text-white font-medium rounded-lg 
                  hover:bg-bgBrandHover dark:hover:bg-dark-bgBrandHover 
                  transition-colors"
            >
              CREAR REELS
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
