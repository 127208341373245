import { create } from 'zustand';
import { Service, ServiceWithAccessResponse, ServiceResponse, ServiceQuerySearchResponse, ActiveServicesResponse  } from '../types/service.types';
import serviceService from '../services/service.service';

interface ServiceStoreState {
  services: ServiceResponse | null;
  activeServices: ActiveServicesResponse | null;
  servicesWithAccess: ServiceWithAccessResponse[] | [];
  searchResults: ServiceQuerySearchResponse[];
  isLoading: boolean;
  error: string | null;
  fetchServices: () => Promise<void>;
  filterServicesByCategory: (categoryName: string) => any[];
  getServicesBySubCategory: (categoryId?: string, subCategoryId?: string) => Promise<void>;
  fetchTopServices: () => Promise<void>;
  updateStatus: (serviceRequestId: string, newStatus: number) => Promise<void>;
  fetchActiveServices: (pageNumber?: number, pageSize?: number) => Promise<void>;
}

const useServiceStore = create<ServiceStoreState>((set, get) => ({
  services: null,
  activeServices: null,
  servicesWithAccess: [],
  searchResults: [],
  isLoading: false,
  error: null,

  fetchServices: async () => {
    set({ isLoading: true });
    try {
      const services = await serviceService.fetchServices();
      set({ services, isLoading: false });
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({ error: error.message, isLoading: false });
      } else {
        set({ error: 'An unknown error occurred', isLoading: false });
      }
    }
  },

  filterServicesByCategory: (categoryName: string) => {
    return (get().services?.data || []).filter((service: Service) => service.subcategoryName === categoryName);
  },

  getServicesBySubCategory: async (categoryId?: string, subCategoryId?: string) => {
    set({ isLoading: true, services: null });
    try {
      const servicesWithAccess = await serviceService.getAllBySubCategory(categoryId, subCategoryId);
      set({ servicesWithAccess, isLoading: false });
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({ error: error.message, isLoading: false });
      } else {
        set({ error: 'An unknown error occurred', isLoading: false });
      }
    }
  },

  fetchTopServices: async () => {
    set({ isLoading: true });
    try {
      const services = await serviceService.fetchTopServices();
      set({ services, isLoading: false });
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({ error: error.message, isLoading: false });
      } else {
        set({ error: 'An unknown error occurred', isLoading: false });
      }
    }
  },

  updateStatus: async (serviceRequestId: string, newStatus: number) => {
    set({ isLoading: true });
    try {
      await serviceService.updateServiceRequestStatus(serviceRequestId, newStatus);
      set({ isLoading: false });
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({ error: error.message, isLoading: false });
      } else {
        set({ error: 'An unknown error occurred', isLoading: false });
      }
    }
  },

  fetchActiveServices: async (pageNumber = 1, pageSize = 20) => {
    set({ isLoading: true, error: null });
    try {
      const activeServices = await serviceService.getActiveServices(pageNumber, pageSize);
      set({ activeServices, isLoading: false });
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({ error: error.message, isLoading: false });
      } else {
        set({ error: 'An unknown error occurred', isLoading: false });
      }
    }
  },
}));

export default useServiceStore;