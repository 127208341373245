import React from "react";
import { HeaderDashboard } from "../organisms/dashboard/HeaderDashboard";
import { SidebarDashboard } from "../organisms/dashboard/SidebarDashboard";
import { Outlet } from "react-router-dom";

export const DashboardLayout: React.FC = () => {
  const [isDarkMode, setIsDarkMode] = React.useState(false);
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);
  const toggleDarkMode = () => setIsDarkMode(!isDarkMode);

  return (
    <div className={`flex h-screen ${isDarkMode ? "dark" : ""}`}>
      <SidebarDashboard />
      <div className="flex-1 overflow-auto">
        <HeaderDashboard />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-4">
          <Outlet />
        </main>
      </div>
    </div>
  );
};
