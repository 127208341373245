import React, { useState } from "react";
import ServiceCard from "./ServiceCard";
import DetailService from "./DetailService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NoServiceMessage } from "./NoServiceMessageCard";
import { useEffect } from "react";
import useServiceRequestStore from "@/store/useServiceRequestStore";
import { ServiceRequest } from "@/types/request.types";
import ServiceRequestDetail from "@/pages/ServiceRequestDetail";
import useProposalStore from "@/store/useProposalStore";

export const ActiveServices: React.FC = () => {
  const [selectedService, setSelectedService] = useState<any | null>(null);
  const { getRequestsByUser, response } = useServiceRequestStore();
  const navigate = useNavigate();
  const { data, fetchProposalsByRequest } = useProposalStore();
  const { t } = useTranslation();

  useEffect(() => {
    getRequestsByUser();
  }, [getRequestsByUser]);

  const activeServices =
    response?.items?.filter(
      (service: ServiceRequest) => String(service.serviceRequestStatus) === "1"
    ) || [];

  const handleRequestServiceClick = () => {
    navigate("/categories");
  };

  const handleServiceClick = (servicio: any) => {
    setSelectedService(servicio);
  };

  useEffect(() => {
    if (selectedService?.serviceRequestId) {
      fetchProposalsByRequest(selectedService.serviceRequestId);
    }
  }, [response?.items, fetchProposalsByRequest]);

  if (selectedService) {
    return (
      <DetailService
        servicio={selectedService}
        onBack={() => setSelectedService(null)}
      />
    );
  }
  /* if (selectedService) {
    return (
      <ServiceRequestDetail
        service={selectedService}
      />
    );
  } */

  return (
    <section>
      <h2 className="text-lg font-semibold mb-4">{t("Servicios Activos")}</h2>
      <div className="flex gap-8 bg-cBackgroundSecondary dark:bg-dark-cBackgroundSecondary rounded-lg p-5">
        {activeServices.length === 0 ? (
          <NoServiceMessage
            title="Actualmente no tienes servicios activos"
            messageOne="¡En XERVIX, no hay límites para ti! Puedes activar tantos servicios como necesites, ajustándonos a cada una de tus necesidades. Explora todas nuestras opciones y descubre cómo podemos ayudarte a que nada se interponga en tu camino. "
            messageTwo="¡Estamos aquí para facilitarte la vida y acompañarte en cada paso!"
            buttonText="Solicitar Servicio"
            onClick={() => alert("Redirigiendo a solicitar servicio...")}
          />
        ) : (
          <div className="flex justify-between w-full gap-8">
            <div className="w-3/4">
              {activeServices.map((servicio: ServiceRequest) => (
                <ServiceCard
                  key={servicio.serviceRequestId}
                  service={servicio}
                  onClick={() => handleServiceClick(servicio)}
                />
              ))}
            </div>
            <NoServiceMessage
              messageOne="¡En XERVIX, no hay límites para ti! Puedes activar tantos servicios como necesites, ajustándonos a cada una de tus necesidades. Explora todas nuestras opciones y descubre cómo podemos ayudarte a que nada se interponga en tu camino. "
              messageTwo="¡Estamos aquí para facilitarte la vida y acompañarte en cada paso!"
              buttonText="Solicitar Servicio"
              onClick={() => alert("Redirigiendo a solicitar servicio...")}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default ActiveServices;
