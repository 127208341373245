import { API_ROUTES } from '@/constants/apiRoutes';
import { xervixApi } from '@/config/axios.config';
import { EvaluationRequest } from '@/types/evaluation.types';

const submitEvaluation = async (data: EvaluationRequest) => {
    try {
        const response = await xervixApi.post(API_ROUTES.EVALUATION.SUBMIT_EVALUATION, data);
        return response.data;
    } catch (error: any) {
        if(error.response){
            throw error;
        }
        throw new Error(error);
    }
};

export default {
    submitEvaluation
};