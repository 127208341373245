import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useServiceStore from '../store/useServiceStore';
import routesConfig from '../config/routes.config';
import { SubCategory } from '@/types/category.types';
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/24/outline";






const CategoryServicesPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<{id: string }>();
  const category = location.state?.category;
  const { isLoading, error } = useServiceStore();

  const handleClick = ( serviceId: SubCategory,) => {
    if(!id || !serviceId) return;
    navigate(routesConfig.serviceForm.replace(':categoryId', id).replace(':subCategoryId', serviceId.subCategoryId),{state: {urlImg: serviceId?.urlImage}});
  };

  if (!category) {
    return <div className="text-center py-4">No category selected</div>;
  }

  if (isLoading) {
    return (
        <div className="container mx-auto px-4 py-8 xl:max-w-[90%] 2xl:max-w-[1600px] min-h-screen">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-12">
            <div className="lg:col-span-2">
              <div className="text-4xl font-bold text-cText dark:text-dark-cText mb-6">
                <div className="w-48 h-8 bg-gray-300 dark:bg-gray-700 rounded animate-pulse"></div>
              </div>
              <div className="w-full h-64 bg-gray-300 dark:bg-gray-700 object-cover rounded-lg mb-8 animate-pulse"></div>
              <div className="bg-cBackground dark:bg-dark-cBackground p-6 rounded-lg shadow-md mb-12">
                <div className="text-2xl font-bold text-cText dark:text-dark-cText mb-4">
                  <div className="w-36 h-6 bg-gray-300 dark:bg-gray-700 rounded animate-pulse"></div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="col-span-1">
                    <div className="text-cTextSecondary dark:text-dark-cTextSecondary">
                      <div className="w-full h-4 bg-gray-300 dark:bg-gray-700 rounded animate-pulse"></div>
                    </div>
                    <div className="mt-4 w-full h-10 bg-gray-300 dark:bg-gray-700 border border-cBorder dark:border-dark-cBorder rounded-lg animate-pulse"></div>
                  </div>
                  <div className="col-span-1">
                    <div className="text-cTextSecondary dark:text-dark-cTextSecondary mt-4 md:mt-0">
                      <div className="w-full h-4 bg-gray-300 dark:bg-gray-700 rounded animate-pulse"></div>
                    </div>
                    <div className="flex mt-4">
                      <div className="flex-1 h-10 bg-gray-300 dark:bg-gray-700 border border-cBorder dark:border-dark-cBorder rounded-lg animate-pulse"></div>
                      <div className="bg-gray-300 dark:bg-gray-700 text-textOnBrand px-6 py-2 rounded-lg ml-2 animate-pulse"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:col-span-1">
              <div className="mb-12">
                <div className="text-3xl font-bold text-cText dark:text-dark-cText mb-6">
                  <div className="w-48 h-8 bg-gray-300 dark:bg-gray-700 rounded animate-pulse"></div>
                </div>
                <ul className="space-y-4">
                  {Array.from({ length: 6 }).map((_, index) => (
                    <li
                      key={index}
                      className="bg-cBackground dark:bg-dark-cBackground p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow cursor-pointer"
                    >
                      <div className="w-36 h-6 bg-gray-300 dark:bg-gray-700 rounded animate-pulse mb-2"></div>
                      <div className="w-24 h-4 bg-gray-300 dark:bg-gray-700 rounded animate-pulse mb-2"></div>
                      <div className="w-full h-4 bg-gray-300 dark:bg-gray-700 rounded animate-pulse mb-2"></div>
                      <div className="w-full h-4 bg-gray-300 dark:bg-gray-700 rounded animate-pulse mb-2"></div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="bg-cBackground dark:bg-dark-cBackground p-6 rounded-lg shadow-md">
                <h2 className="text-2xl font-bold text-cText dark:text-dark-cText mb-4">
                  <div className="w-48 h-6 bg-gray-300 dark:bg-gray-700 rounded animate-pulse"></div>
                </h2>
                <div className="w-full h-10 bg-gray-300 dark:bg-gray-700 border border-cBorder dark:border-dark-cBorder rounded-lg animate-pulse"></div>
              </div>
            </div>
          </div>
        </div>
    );
  }

  /* if (error) {
    return <div className="text-center py-4 text-red-500">Error: {error}</div>;
  } */

  return (
      <div className="container mx-auto px-1 lg:px-4 py-6 lg:p-8 xl:max-w-[90%] 2xl:max-w-[1600px] min-h-screen ">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-12 mt-0 lg:mt-6">
          <div className="lg:col-span-2 w-[100%] lg:w-[90%]  order-2 lg:order-1">
            <h1 className="text-[16px] lg:text-[22px] font-semibold text-cText dark:text-dark-cText mb-2 lg:mb-6">
              Servicios de {category?.name} que marcan la diferencia
            </h1>
            <img
              src={category?.urlImage}
              alt={category?.name}
              className="w-full h-44 lg:h-96 object-cover rounded-lg mb-4 lg:mb-8"
            />
            <div className="bg-cBackground dark:bg-dark-cBackground py-6 rounded-lg mb-2 lg:mb-12">
              <h2 className="text-[16px] lg:text-[20px] font-semibold text-cText dark:text-dark-cText mb-2 lg:mb-4">
                ¿No encuentras lo que necesitas?
              </h2>
              <div className="">
               
                  <p className="text-cTextSecondary dark:text-dark-cTextSecondary text-[14px] lg:text-[16px] font-normal leading-6 lg:leading-8">
                    No te preocupes, cuéntanos qué servicio estás buscando. Encontraremos empresas que te puedan ayudar y actualizaremos este servicio a nuestro portafolio.
                  </p>

                  <div className='flex justify-items-center mt-6 justify-between gap-2'>
                      <div className='w-80 px-2 py-2 flex justify-items-center border border-cBorder dark:border-dark-cBorder rounded-lg bg-cBackground dark:bg-dark-cBackgroundSecondary'>
                          <ArrowRightCircleIcon className=" stroke-cBorder dark:fill-transparent size-6" />
                          <input
                                type="text"
                                placeholder="Describe el servicio"
                                className=" ml-3   text-cText dark:text-dark-cText text-sm"
                              />
                              
                      </div>

                      <button className="bg-textBrand text-white  rounded-lg w-60"> ENVIAR </button>

                  </div>              
             
              {/*   <div className="col-span-1">
                  <p className="text-cTextSecondary dark:text-dark-cTextSecondary mt-4 md:mt-0">
                    También puedes enviarnos un correo electrónico con más detalles.
                  </p>
                  <div className="flex mt-4">
                    <input
                      type="email"
                      placeholder="Tu correo electrónico"
                      className="flex-1 px-4 py-2 border border-cBorder dark:border-dark-cBorder rounded-lg bg-cBackground dark:bg-dark-cBackgroundSecondary text-cText dark:text-dark-cText"
                    />
                    <button className="bg-bgBrand dark:bg-dark-bgBrand text-textOnBrand px-6 py-2 rounded-lg ml-2">
                      Enviar
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="lg:col-span-1 order-1 lg:order-3 ">
            <div className="mb-0 lg:mb-12">
              <h2 className="text-[16px] lg:text-[20px] font-semibold text-cText dark:text-dark-cText">
                Todos los servicios en un solo lugar!
              </h2>
              <h3 className="text-[14px] lg:text-[20px] font-semibold text-cText dark:text-dark-cText mt-8 lg:mt-12">
                Selecciona una Subcategoría
              </h3>
              <ul className="space-y-2 mt-8 ">
                {category?.subCategories.map((subCategory: SubCategory) => (
                  <div>

                    <div className='flex justify-between items-center'>
                    <li
                    key={subCategory.subCategoryId}
                    className=" py-1 lg:py-2  transition-shadow cursor-pointer"
                    onClick={() => handleClick(subCategory)}
                  >
                    <h3 className="text-[14px] lg:text-[16px] font-medium lg:font-medium text-cTextSecondary dark:text-dark-cText  hover:text-textBrand">
                      {subCategory.name}
                    </h3>
                    
                  </li>

                  <ChevronRightIcon className=" stroke-cBorder dark:fill-transparent size-6 " />

                    </div>   

                  <hr className='h-px bg-gray-200 border-0 dark:bg-gray-700 mb-4'></hr>
                  </div>
                  
                ))}
              </ul>
            </div>
           {/*  <div className="bg-cBackground dark:bg-dark-cBackground py-6 rounded-lg shadow-md">
              <h2 className="text-[16px] font-semibold text-cText dark:text-dark-cText mb-4">
                Si no estás seguro, busca tu servicio directamente
              </h2>
              <input
                type="text"
                placeholder="¿Qué servicio necesitas?"
                className="w-full px-4 py-2 border border-cBorder dark:border-dark-cBorder rounded-lg bg-cBackground dark:bg-dark-cBackgroundSecondary text-cText dark:text-dark-cText"
              />
            </div> */}
          </div>
        </div>
      </div>
  );
};

export default CategoryServicesPage;
