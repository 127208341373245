import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../atoms/Button";

const ViewAllButton = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="w-full text-right  mt-4 xl:mt-0">
      <a className="text-[12px] text-[#F97068] hover:text-[#ff5c53] font-medium cursor-pointer "
      onClick={() => navigate("/categories", { state: { isDashboard: false } })}>{t("landingPage.viewAllButton")}</a>
      {/* <Button
        onClick={() => navigate("/categories", { state: { isDashboard: false } })}
        variant="outlined"
        size="medium"
        className="text-[#F97068] hover:text-[#F97068] lg:w-max text-sm md:text-base lg:font-medium"
      >
        {t("landingPage.viewAllButton")}
      </Button> */}
    </div>
  );
};

export default ViewAllButton;