import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { Proposal } from "@/types/proposal.types";
import useProfileStore from "@/store/useProfileStore";


import {
  ArrowsUpDownIcon,
  StarIcon as StarOutline,
} from "@heroicons/react/24/outline";
import { StarIcon as StarFilled } from "@heroicons/react/24/solid";
import routesConfig from "@/config/routes.config";

const StarRating = ({ rating }: { rating: number }) => {
  const totalStars = 5;
  const fullStars = Math.floor(rating);
  const partialStar = rating % 1;
  const emptyStars = totalStars - fullStars - (partialStar > 0 ? 1 : 0);

  return (
    <div className="flex flex-row items-center">
      {/* Full stars */}
      {Array.from({ length: fullStars }).map((_, index) => (
        <StarFilled key={`full-${index}`} className="w-4 h-4 text-yellow-400" />
      ))}

      {/* Partial star */}
      {partialStar > 0 && (
        <div className="relative w-4 h-4">
          <div className="absolute inset-0">
            <StarOutline className="w-4 h-4 text-yellow-400" />
          </div>
          <div
            className="absolute inset-0 overflow-hidden"
            style={{ width: `${partialStar * 100}%` }}
          >
            <StarFilled className="w-4 h-4 text-yellow-400" />
          </div>
        </div>
      )}

      {/* Empty stars */}
      {Array.from({ length: emptyStars }).map((_, index) => (
        <StarOutline
          key={`empty-${index}`}
          className="w-4 h-4 text-yellow-400"
        />
      ))}
    </div>
  );
};

// Usage in ProposalsSection:
<div className="flex items-center gap-2 mt-2"></div>;

type SortOption = {
  label: string;
  value: "location" | "publishTime" | "price" | "stars";
};

interface SortDropdownProps {
  onSort: (value: SortOption["value"]) => void;
}

const SortDropdown = ({ onSort }: SortDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const sortOptions: SortOption[] = [
    { label: "Ubicación", value: "location" },
    { label: "Fecha de publicación", value: "publishTime" },
    { label: "Precio", value: "price" },
    { label: "Calificación", value: "stars" },
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
      >
        <ArrowsUpDownIcon className="w-5 h-5" />
        Ordenar
      </button>

      {isOpen && (
        <div className="absolute right-0 z-10 mt-2 w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="py-1">
            {sortOptions.map((option) => (
              <button
                key={option.value}
                onClick={() => {
                  onSort(option.value);
                  setIsOpen(false);
                }}
                className="w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};


interface ProposalsSectionProps {
  proposals: Proposal[];
}

const ProposalsSection: React.FC<ProposalsSectionProps> = ({
  proposals
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { professional, getProfessionalInfo } = useProfileStore();
  const [selectedFilter, setSelectedFilter] = useState("");
  const [filteredProposals, setFilteredProposals] = useState(proposals);
  const [selectedSort, setSelectedSort] =
    useState<SortOption["value"]>("publishTime");

  useEffect(() => {
    setFilteredProposals(proposals);
  }, [proposals]);

  useEffect(() => {
    if (proposals.length > 0) {
      // Get professional info for each proposal
      proposals.forEach(proposal => {
        getProfessionalInfo(proposal.professionalId);
      });
    }
  }, [proposals]);

  const handleSort = (value: SortOption["value"]) => {
    setSelectedFilter(value);

    const sortedProposals = [...proposals].sort((a, b) => {
      switch (value) {
        /* case "location":
          return a.location.localeCompare(b.location); */
        case "publishTime":
          return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        case "price":
          return parseFloat(a.proposalValue ?? 0) - parseFloat(b.proposalValue ?? 0);
        case "stars":
          return (b.rating ?? 0) - (a.rating ?? 0);
        default:
          return 0;
      }
    });
    setFilteredProposals(sortedProposals);
  };


  // Función para calcular el tiempo transcurrido
  const calculateElapsedTime = (serverDateString: string): string => {
    // Convertir la fecha del servidor a un objeto Date (se asume UTC)
    const serverDateUTC = new Date(serverDateString);

    // Obtener la fecha/hora actual en UTC
    const nowUTC = new Date();

    // Calcular la diferencia en milisegundos
    const elapsedMilliseconds = nowUTC.getTime() - serverDateUTC.getTime();

    // Convertir la diferencia a horas, minutos y segundos
    const seconds = Math.floor(elapsedMilliseconds / 1000) % 60;
    const minutes = Math.floor(elapsedMilliseconds / (1000 * 60)) % 60;
    const hours = Math.floor(elapsedMilliseconds / (1000 * 60 * 60));

    return `Hace ${hours} horas y ${minutes} minutos`;
  };


  const handleProposalClick = (proposal: Proposal) => {
    navigate(routesConfig.proposalDetail.replace(":id", proposal.proposalId), {
      state: { proposal, professional },
    });
  };

  return (
    <section className="p-6">
      <h3 className="font-medium text-lg text-center">Mis Propuestas</h3>
      <div className="flex justify-between items-center mb-6">
        <SortDropdown onSort={handleSort} />
        <span className="font-semibold">
          {t("requestsAndProposals.proposals.title")} {proposals.length}
        </span>
      </div>
      {filteredProposals.length === 0 ? (
        <div className="text-center">
          <img
            src="assets/icons/brand/no-requests.png"
            alt="No proposals"
            className="mx-auto mb-4 w-48"
          />
          <h3 className="text-xl font-semibold mb-2">
            {t("requestsAndProposals.proposals.noProposals.title")}
          </h3>
          <p className="text-gray-600">
            {t("requestsAndProposals.proposals.noProposals.description")}
          </p>
        </div>
      ) : (
        <div className="grid gap-4">
          {filteredProposals.map((proposal) => (
            <div
              key={proposal.proposalId}
              onClick={() => handleProposalClick(proposal)}
              className="pr-6 pl-4 py-4 flex items-start gap-4 rounded-lg transition-all shadow-lg bg-white hover:shadow-xl max-w-prose cursor-pointer"
            >
              <img
                src={
                  professional?.companyImage ||
                  `https://eu.ui-avatars.com/api/?name=${professional?.name || 'User'}+${professional?.lastNames || ''}&size=250`
                }
                alt="imageProposals"
                className="size-16 rounded-lg object-cover flex-shrink-0"
              />
              <div className="flex flex-col flex-grow min-w-0">
                <div className="w-full">
                  <div className="flex justify-between items-center">
                    <h2 className="font-medium text-xl text-cText dark:text-dark-cText">
                      {professional?.name} {professional?.lastNames}
                    </h2>
                    <div className="flex items-center gap-2 flex-shrink-0">
                      <StarRating rating={proposal.rating ?? 0} />
                      <span className="text-sm text-cTextSecondary">
                        ({proposal.rating})
                      </span>
                    </div>
                  </div>
                  <p className="text-cTextSecondary break-words line-clamp-3 mt-2">
                    {proposal.descriptionProposet}
                  </p>
                </div>
                <div className="flex justify-between items-center mt-4 w-full">
                  <p className="text-lg font-semibold">{/* proposal.location */}</p>
                  <p className="text-gray-600">
                    {calculateElapsedTime(proposal.createdAt)}
                  </p>
                  <span className="text-lg font-semibold flex-shrink-0">
                    Precio: {proposal.proposalValue ?? 0}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default ProposalsSection;
