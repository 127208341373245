// @config
import { xervixApi } from '../config/axios.config';

// @constants
import { API_ROUTES } from '../constants/apiRoutes';

// @types
import {
  ServiceResponse,
  ServiceWithAccessResponse,
  ActiveServicesResponse,
  ISuggestion,
} from '../types/service.types';

const fetchServices = async (): Promise<ServiceResponse> => {
  const response = await xervixApi.get(API_ROUTES.SERVICES.FETCH_SERVICES, {
    params: {
      page: 1,
      pageSize: 1000,
    },
  });
  return response.data as ServiceResponse;
};

const getAllBySubCategory = async (categoryId?: string, subCategoryId?: string): Promise<ServiceWithAccessResponse[]> => {

  const params: Record<string, string | undefined> = {};
  if (categoryId) params.categoryId = categoryId;
  if (subCategoryId) params.subCategoryId = subCategoryId;

  const response = await xervixApi.get(API_ROUTES.SERVICES.BY_CATEGORY_SUBCATEGORY, { params });
  return response.data;
};

// Bug in Backend
const fetchService = async (serviceId: string): Promise<ServiceResponse> => {
  const response = await xervixApi.get(API_ROUTES.SERVICES.FETCH_SERVICE(serviceId));
  return response.data as ServiceResponse;
};

const fetchTopServices = async (): Promise<ServiceResponse> => {
  const response = await xervixApi.get(API_ROUTES.SERVICES.FETCH_TOP_SERVICES);
  return response.data as ServiceResponse;
}

// GET /enums/service-request-statuses
const fetchServiceRequestStatuses = async () => {
  const response = await xervixApi.get("/enums/service-request-statuses");
  return response.data;
};

// PATCH /api/service-request/{serviceRequestId}/status
const updateServiceRequestStatus = async (serviceRequestId: string, newStatus: number) => {
  const response = await xervixApi.patch(`${API_ROUTES.SERVICES.FETCH_SERVICE_STATUS(serviceRequestId)}`, {
    newStatus
  });
  return response.data;
};

const getActiveServices = async (pageNumber = 1, pageSize = 20): Promise<ActiveServicesResponse> => {
  const response = await xervixApi.get(`/api/service-request/active-for-professional`, {
    params: { pageNumber, pageSize },
  });
  return response.data;
};

const saveSuggestion = async (suggestion: ISuggestion) => {
  try {
    const response = await xervixApi.post('/service-suggestion', suggestion);
    return response.data;
  } catch (error) {
    throw new Error('Failed to save suggestion');
  }
};

export default {
  fetchServices,
  fetchService,
  getAllBySubCategory,
  fetchTopServices,
  fetchServiceRequestStatuses,
  updateServiceRequestStatus,
  getActiveServices,
  saveSuggestion
};
