import { xervixApi, xervixApiMultipart } from '@/config/axios.config'; // Importamos xervixApi para manejar automáticamente el token
import { API_ROUTES } from '@/constants/apiRoutes';
import { CreateServiceRequest, UploadImagesRequest, RequestFilters, ServiceRequestDetailsResponse, ServiceRequestResponse } from '../types/request.types';


const submitServiceRequest = async (data: CreateServiceRequest): Promise<{serviceRequestId: string}> => {
  try {
    const response = await xervixApi.post(API_ROUTES.REQUEST.SUBMIT_REQUEST, data);
    return response.data;
  } catch (error: any) {
    if(error.response){
      throw error;
    }
    throw new Error(error);
  }
};

const uploadImagesServiceRequest = async (serviceRequestId: string, dataFields: UploadImagesRequest): Promise<any> => {
  const formData = new FormData();
  dataFields.files.forEach((file) => {
    formData.append('images', file);
  });
  const response = await xervixApi.post(
    API_ROUTES.REQUEST.UPLOAD_IMAGES(serviceRequestId),
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return response.data;
};

const fetchModeServiceRequest = async (): Promise<any> => {
  const response = await xervixApi.get(
    API_ROUTES.ENUMS.SERVICE_MODES
  );
  return response.data;
};

const fetchRequestsByUser = async (): Promise<any> => {
  const response = await xervixApi.get(
    API_ROUTES.REQUEST.FETCH_REQUESTS,
    {
      params: {
        useUserId: true,
      },
    }
  );
  return response.data;
}

const fetchRequestByStatus = async (serviceRequestId:string, serviceRequestStatus: String): Promise<any> => {
  const response = await xervixApi.get(
    API_ROUTES.REQUEST.FETCH_REQUESTS,
    {
      params: {
        serviceRequestId: serviceRequestId,
        serviceRequestStatus: serviceRequestStatus,
      },
    }
  );
  return response.data;
}

const fetchFilteredRequests = async (filters: RequestFilters): Promise<any> => {
  const response = await xervixApi.get(API_ROUTES.REQUEST.FETCH_REQUESTS, {
    params: {
      ...filters
    }
  });
  return response.data;
};


const fetchServiceRequestDetails = async (
  page = 1,
  pageSize = 10,
  orderBy = "createdDate",
  sortDirection = "desc"
): Promise<ServiceRequestDetailsResponse> => {
  const response = await xervixApi.get(API_ROUTES.REQUEST.FETCH_REQUEST_DETAILS, {
    params: { page, pageSize, orderBy, sortDirection },
  });
  return response.data;
};

const fetchActiveForProfessional = async (filters: RequestFilters): Promise<ServiceRequestResponse> => {
  const response = await xervixApi.get(API_ROUTES.REQUEST.FETCH_ACTIVE_FOR_PROFESSIONAL, {
    params: filters
  });
  return response.data;
};

export default {
  submitServiceRequest,
  uploadImagesServiceRequest,
  fetchModeServiceRequest,
  fetchRequestsByUser,
  fetchRequestByStatus,
  fetchFilteredRequests,
  fetchServiceRequestDetails,
  fetchActiveForProfessional,
};


