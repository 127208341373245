import { useSpinnerStore } from '@/store/useSpinnerStore';
import React from 'react';
import FacebookLogin from 'react-facebook-login';
import { useTranslation } from 'react-i18next';
/* import Button from '../../atoms/Button'; */

interface Props {
  onSuccess?: (data: any) => void;
  onFailure?: (error: any) => void;
}

const FacebookAuthButton: React.FC<Props> = ({ onSuccess, onFailure }) => {
  const { t } = useTranslation();
  const { openSpinner } = useSpinnerStore();

  const responseFacebook = (response: any) => {
    openSpinner(true);
    if (response.accessToken) {
      if (onSuccess) {
        onSuccess(response);
      }
      openSpinner(false);
    } else {
      if (onFailure) {
        onFailure(response);
      }
      openSpinner(false);
    }
  };

  return (

    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID!}
      autoLoad={false}
      fields="name,email,picture"
      callback={responseFacebook}
      onFailure={responseFacebook}
      textButton={t('facebook')}
      cssClass="w-full h-12 flex items-center justify-center gap-3 px-6 bg-blue-600 text-white p-2 rounded-xl"
      icon="fa-facebook"
    />
  );
};

export default FacebookAuthButton;
