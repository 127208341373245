// src/components/atoms/PeopleRequestCard.tsx
import React from "react";

interface PeopleRequestCardProps {
  name: string;
  service: string;
  timeAgo: string;
  urgent?: boolean;
  onClick: () => void;
  isSelected: boolean;
}

export const PeopleRequestCard: React.FC<PeopleRequestCardProps> = ({
  name,
  service,
  timeAgo,
  urgent = false,
  onClick,
  isSelected,
}) => {
  return (
    <div
      className={`flex items-center justify-between p-4 rounded-lg shadow-md hover:shadow-lg cursor-pointer transition duration-300 ${
        isSelected ? 'border border-bgBrand' : 'bg-cBackground dark:bg-dark-cBackground'
      }`}
      onClick={onClick}
    >
      {/* Foto y detalles */}
      <div className="flex items-center gap-4">
        <img
          src={`https://via.placeholder.com/48`}
          alt="Avatar"
          className="w-12 h-12 rounded-full object-cover border border-cBorder"
        />
        <div>
          <p className="font-bold text-cText">{name}</p>
          <p className="text-cTextSecondary text-sm">{service}</p>
          <p className="text-cTextSecondary text-xs">{timeAgo}</p>
        </div>
      </div>

      {/* Estado */}
      <div className="flex flex-col items-end">
        <button
          className={`px-4 py-1 text-sm font-medium rounded-md ${
            isSelected ? 'bg-bgBrand text-textOnBrand': 'bg-bgSuccess text-textOnSuccess'
          }`}
        >
          ABRIR
        </button>
        {urgent && (
          <span className="mt-2 text-xs font-semibold text-bgError">
            URGENTE
          </span>
        )}
      </div>
    </div>
  );
};
