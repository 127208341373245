// @vendors
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// @guards
import NextStepGuard from "./guards/NextStepGuard";
import { OnboardingCompletionGuard } from "./guards/OnboardingCompletionGuard";

// @config
import routesConfig from "./config/routes.config";
import "./i18n/config";

// @pages
import DashboardPage from "./pages/DashboardPage";
import CreateAccountPage from "./pages/auth/CreateAccountPage";
import LandingPage from "./pages/LandingPage";
import ReelsPage from "./pages/ReelsPage";
import SplashPage from "./pages/SplashPage";
import LoginPage from "./pages/auth/LoginPage";
import VerifyCodePage from "./pages/auth/VerifyCodePage";
import ViewTermsAndConditionsPage from "./pages/ViewTermsAndConditions";
import CategoriesPage from "./pages/CategoriesPage";
import CategoryServicesPage from "./pages/CategoryServicesPage";
import ServiceFormPage from "./pages/ServiceFormPage";
import AdminPage from "./pages/admin xervix/CamiloPage";
import AdminPageUser from "./pages/admin xervix/AdminPageUser";
import AdminPageEmpresas from "./pages/admin xervix/AdminPageEmpresas";
import AdminPageSolicitudes from "./pages/admin xervix/AdminPageSolicitudes";
import AdminPageMarket from "./pages/admin xervix/AdminPageMarket";
import AdminPagePagos from "./pages/admin xervix/AdminPagePagos";
import AdminPageEstadisiticas from "./pages/admin xervix/AdminPageEstadisticas";
import AdminDetailCompanyPage from "./pages/admin xervix/detail_company_page";
import AdminRequestClose from "./pages/admin xervix/AdminRequesteClosa";
import AdminRequestResume from "./pages/admin xervix/Admin_request_resume";
import AdminPageOpiniones from "./pages/admin xervix/AdminPage_opiniones";
import AdminPageOpinionesHistorial from "./pages/admin xervix/AdminPage_opiniones_historial";
import AdminPageReelsHistorial from "./pages/admin xervix/AdminPage_Reels_resume";
import AdminPagePagosGanancias from "./pages/admin xervix/AdminPage_Pagos_Ganancias";
import AdminPagePagosPendientes from "./pages/admin xervix/AdminPage_Pagos_Pendientes";
import MyRequestsPage from "./pages/MyRequestsPage";
import SignUpForm from "./pages/auth/SignUpForm";
import OnboardingProfessionalPage from "./pages/OnboardingProfessionalPage";
import UserProfessionalPage from "./pages/UserProfessionalPage";
import MyServicesPage from "./pages/MyServicesPage";
import { ProposalsPage } from "./pages/ProposalsPage";
import ServicesDashboard from "./pages/ServicesDashboard";
import EvaluationForm from "./pages/EvaluationForm";
import CreatePostPage from "./pages/CreatePostPage";
import MarketplaceDashboard from "./pages/MarketplaceDashboard";

// @components
import { ProtectedRoute } from "./components/templates/ProtectedRoutes";
import { BaseLayout } from "./components/templates/BaseLayout";
import { AuthLayout } from "./components/templates/AuthLayout";
import ProposalDetailSection from "./components/molecules/sidebarRequest/ProposalDetailSection";
import { DashboardLayout } from "./components/templates/DashboardLayout";
import Spinner from "./components/spinner/Spinner";

// @types
import { UserRole } from "./types/auth.types";

const App = () => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  if (!clientId) {
    alert("Google Client ID is not defined");
  }
  return (
    <GoogleOAuthProvider clientId={clientId || ""}>
      <Spinner />
      <ToastContainer />
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route element={<AuthLayout />} >
            <Route path={routesConfig.login} element={<LoginPage />} />
            <Route path={routesConfig.createAccount} element={<CreateAccountPage />} />
            <Route path={routesConfig.createAccountForm} element={<SignUpForm userType="USER" />} />
            <Route path={routesConfig.createAccountCompany} element={<CreateAccountPage />} />
            <Route path={routesConfig.createAccountCompanyForm} element={<SignUpForm userType="PROFESSIONAL" />} />
            <Route path={routesConfig.validateEmail} element={<VerifyCodePage />} />
            <Route path={routesConfig.splash} element={<SplashPage />} />
            <Route path={routesConfig.myReels} element={<ReelsPage />} />
          </Route>

          <Route element={<BaseLayout />} >
            <Route path={routesConfig.home} element={<LandingPage />} />
            <Route path={routesConfig.categoriesPage} element={<CategoriesPage />} />
            <Route path={routesConfig.categoryServicesPage} element={<CategoryServicesPage />} />
            <Route path={routesConfig.serviceForm} element={<ServiceFormPage />} />
            <Route path={routesConfig.proposalDetail} element={<ProposalDetailSection />} />
            <Route path={routesConfig.evaluationForm} element={<EvaluationForm />} />
            {/* User Routes */}
            <Route
              element={
                <ProtectedRoute allowedRoles={[UserRole.USER, UserRole.PROFESSIONAL]} />
              }
            >
              <Route path={routesConfig.myRequests} element={<MyRequestsPage />} />
              <Route path={routesConfig.myServices} element={<MyServicesPage />} />
            </Route>
          </Route>


          {/* Professional Routes */}

          <Route
            element={
              <ProtectedRoute allowedRoles={[UserRole.PROFESSIONAL]} />}
          >
            <Route path={routesConfig.onboardingProfessionalPage} element={<OnboardingProfessionalPage />} />
            <Route element={<DashboardLayout />} >
              <Route element={<OnboardingCompletionGuard />}>
                <Route path={routesConfig.dashboardProfessional} element={<DashboardPage />} />
                <Route path={routesConfig.servicesProfessional} element={<ServicesDashboard />} />
                <Route path={routesConfig.proposalsProfessional} element={<ProposalsPage />} />
                <Route path={routesConfig.createPostProfessional} element={<CreatePostPage />} />
                <Route path={routesConfig.userProfessional} element={<UserProfessionalPage />} />
                <Route path={routesConfig.marketplaceProfessional} element={<MarketplaceDashboard />} />
                <Route path={routesConfig.termsAndConditionsPage} element={<ViewTermsAndConditionsPage />} />
              </Route>
            </Route>
          </Route>

          {/* Admin Routes */}
          <Route element={<ProtectedRoute allowedRoles={[UserRole.ADMIN]} />}>
            <Route path={routesConfig.AdminPage} element={<AdminPage />} />
            <Route
              path={routesConfig.AdminPageUser}
              element={<AdminPageUser />}
            />
            <Route
              path={routesConfig.AdminPageEmpresas}
              element={<AdminPageEmpresas />}
            />
            <Route
              path={routesConfig.AdminPageSolicitudes}
              element={<AdminPageSolicitudes />}
            />
            <Route
              path={routesConfig.AdminPageOpiniones}
              element={<AdminPageOpiniones />}
            />
            <Route
              path={routesConfig.AdminPageMarketPlace}
              element={<AdminPageMarket />}
            />
            <Route
              path={routesConfig.AdminPageHistorial}
              element={<AdminPagePagos />}
            />
            <Route
              path={routesConfig.AdminPagePagosGanancias}
              element={<AdminPagePagosGanancias />}
            />
            <Route
              path={routesConfig.AdminPagePagospendientes}
              element={<AdminPagePagosPendientes />}
            />
            <Route
              path={routesConfig.AdminPageEstadisticas}
              element={<AdminPageEstadisiticas />}
            />
            <Route
              path={routesConfig.AdminDetailCompanyPage}
              element={<AdminDetailCompanyPage />}
            />
            <Route
              path={routesConfig.AdminRequestClose}
              element={<AdminRequestClose />}
            />
            <Route
              path={routesConfig.AdminRequestResume}
              element={<AdminRequestResume />}
            />
            <Route
              path={routesConfig.AdminPageOpinionesHistorial}
              element={<AdminPageOpinionesHistorial />}
            />
            <Route
              path={routesConfig.AdminPageReelsHistorial}
              element={<AdminPageReelsHistorial />}
            />
          </Route>

          <Route path="*" element={<NextStepGuard />} />
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
};

export default App;
