// src/components/templates/ServicesDashboard.tsx
import React, { useState, useMemo } from "react";
import PeopleServiceCard from "@/components/molecules/PeopleServiceCard";
import ServiceDetailsProfessional from "@/components/molecules/ServiceDetailsProfessional";
import useServiceStore from "@/store/useServiceStore";
import useServiceRequestStore from "@/store/useServiceRequestStore";
import { ServiceRequest, RequestFilters } from "@/types/request.types";


const ServicesDashboard: React.FC = () => {
  const { fetchActiveForProfessional, activeForProfessional  } = useServiceRequestStore();
  const [filters, setFilters] = useState<RequestFilters>({
    pageNumber: 1,
    pageSize: 20,
  });

  React.useEffect(() => {
    fetchActiveForProfessional(filters);
  }, [fetchActiveForProfessional]);

  const activeServices = activeForProfessional?.items?.filter(
    service => service.serviceRequestStatus === "ACTIVE"
  ) || [];

  const completedServices = activeForProfessional?.items?.filter(
    service => service.serviceRequestStatus === "COMPLETED"
  ) || [];

  return (
    <div className="grid grid-cols-12 gap-6 p-6 bg-cBackgroundSecondary min-h-screen">
      {/* Primera columna */}
      <div className="col-span-3 space-y-6">
        {/* Información general */}
        <div className="bg-white rounded-lg shadow-md p-8">
          <h2 className="text-xl font-medium mb-4">Información general</h2>
          <div className="grid grid-cols-3 gap-6">
            <div className="flex flex-col items-center justify-center bg-bgSuccess text-textOnSuccess rounded-2xl py-4">
              <p className="text-xl font-bold">{activeServices.length|| 0}</p>
              <p className="text-sm">Activos</p>
            </div>
            <div className="flex flex-col items-center justify-center bg-bgSuccess text-textOnSuccess rounded-2xl py-4">
              <p className="text-xl font-bold">{completedServices.length}</p>
              <p className="text-sm">Ejecutados</p>
            </div>
            <div className="flex flex-col items-center justify-center bg-bgSuccess text-textOnSuccess rounded-2xl py-4">
              <p className="text-xl font-bold">1</p>
              <p className="text-sm">Cancelados</p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-4">
          <h2 className="text-lg font-bold mb-4">Mis Servicios Activos</h2>
          <div className="space-y-4 overflow-y-auto max-h-80">
          {activeServices.map((service: ServiceRequest) => (
              <PeopleServiceCard
                key={service.serviceRequestId}
                service={service}
                onClick={() => {}}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Segunda columna */}
      <div className="col-span-4 bg-white rounded-lg shadow-md p-4">
        <h2 className="text-lg font-bold mt-8 mb-4">Servicios Ejecutados</h2>
        {completedServices.map((service: ServiceRequest) => (
            <PeopleServiceCard
              key={service.serviceRequestId}
              service={service}
              onClick={() => {}}
            />
          ))
        }
      </div>

      {/* Tercera columna */}
      <div className="col-span-5 bg-white rounded-lg shadow-md p-4">
        <ServiceDetailsProfessional />
      </div>
    </div>
  );
};

export default ServicesDashboard;
