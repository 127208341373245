/* eslint-disable react-hooks/exhaustive-deps */
// vendors
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { toast } from "react-toastify";

// @store
import useOnboardingStore from "@/store/useOnboardingStore";
import { useSpinnerStore } from "@/store/useSpinnerStore";

// @helper
import {
  ArrowDownTrayIcon,
  ArrowUpTrayIcon,
  DocumentTextIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";

// @components
import Modal from "@/components/Modals/Modal";

const DOCUMENT_TYPES = {
  LOGO: 0,
  CEDULA_REPRESENTANTE: 1,
  CAMARA_COMERCIO: 2,
  CERTIFICADO_BANCARIO: 3,
  RUT: 4,
} as const;

// Interface for document mapping
interface DocumentMapping {
  key: string;
  type: number;
  label: string;
}

const BUSINESS_TYPES = {
  NATURAL: "30f08549-8bc1-4a2f-9c74-a8b648d63d05" /* 'Persona Natural' */,
  COMPANY:
    "8a0cac57-b85b-4c80-8bdb-a8174267e079" /* 'Empresa Legalmente Constituida' */,
} as const;

const getDocumentConfig = (businessType: string): DocumentMapping[] => {
  if (businessType === BUSINESS_TYPES.NATURAL) {
    return [
      {
        key: "cedula",
        type: DOCUMENT_TYPES.CEDULA_REPRESENTANTE,
        label: "Cédula",
      },
      {
        key: "certificadoBancario",
        type: DOCUMENT_TYPES.CERTIFICADO_BANCARIO,
        label: "Certificado Bancario",
      },
      { key: "rut", type: DOCUMENT_TYPES.RUT, label: "RUT" },
    ];
  }
  return [
    {
      key: "cedulaRepresentante",
      type: DOCUMENT_TYPES.CEDULA_REPRESENTANTE,
      label: "Cédula Representante",
    },
    {
      key: "camaraComercio",
      type: DOCUMENT_TYPES.CAMARA_COMERCIO,
      label: "Cámara de Comercio",
    },
    {
      key: "certificadoBancario",
      type: DOCUMENT_TYPES.CERTIFICADO_BANCARIO,
      label: "Certificado Bancario",
    },
    { key: "rut", type: DOCUMENT_TYPES.RUT, label: "RUT" },
  ];
};

// Add these interfaces after the existing interfaces
interface NaturalPersonForm {
  firstName: string;
  lastName: string;
  identification: string;
  phoneNumber: string;
  description: string;
}

interface CompanyForm {
  companyName: string;
  nit: string;
  phoneNumber: string;
  description: string;
}

type BusinessFormData = NaturalPersonForm | CompanyForm;

// Atomic Components
const InputField: React.FC<{
  label: string;
  id: string;
  value: string;
  onChange: (value: string) => void;
  type?: string;
}> = ({ label, id, value, onChange, type = "text" }) => (
  <div className="mb-6">
    <label htmlFor={id} className="block text-left mb-2">
      {label}
    </label>
    <input
      type={type}
      id={id}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="w-full px-4 py-2 border rounded-lg"
    />
  </div>
);

const TextAreaField: React.FC<{
  label: string;
  id: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}> = ({ label, id, value, onChange, placeholder }) => (
  <div className="mb-6 h-full">
    <label htmlFor={id} className="block text-left mb-2">
      {label}
    </label>
    <textarea
      id={id}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="w-full h-full px-4 py-2 border rounded-lg"
      placeholder={placeholder}
    />
  </div>
);

const FileUploadProgress: React.FC<{
  progress: number;
  fileName: string;
  onCancel: () => void;
}> = ({ progress, fileName, onCancel }) => (
  <div>
    <div className="flex items-center mb-2">
      <span className="text-sm text-gray-700">{fileName || "Cargando archivo..."}</span>
      <button className="ml-auto text-sm text-red-500" onClick={onCancel}>
        Cancelar
      </button>
    </div>
    <div className="w-full bg-gray-200 rounded-full h-2">
      <div
        className="bg-blue-500 h-2 rounded-full"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  </div>
);

// Molecular Components
const LogoUploader: React.FC<{
  logo: File | null;
  onLogoChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
}> = ({ logo, onLogoChange, fileInputRef }) => (
  <div className="mb-4 flex flex-col items-center justify-center">
    <div className="relative w-48 h-48 bg-cBackground dark:bg-dark-cBackground rounded-full flex items-center justify-center overflow-hidden border">
      <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-full cursor-pointer">
        {logo ? (
          <img src={URL.createObjectURL(logo)} alt="Logo" className="w-full h-full object-cover" />
        ) : (
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <ArrowUpTrayIcon className="w-8 h-8 mb-4 text-cTextSecondary" />
            <p className="mb-2 text-sm text-cTextSecondary">
              <span className="font-semibold">Click to upload</span> or drag and drop
            </p>
            <p className="text-xs text-cTextSecondary">
              SVG, PNG, JPG or GIF (MAX. 800x400px)
            </p>
          </div>
        )}
        <input
          id="dropzone-file"
          type="file"
          accept="image/*"
          onChange={onLogoChange}
          ref={fileInputRef}
          className="hidden"
        />
      </label>
    </div>
    <div className="mt-4">
      <h2 className="text-lg text-cText dark:text-dark-cText font-medium">
        Selecciona tu logo
      </h2>
      <p className="text-cTextSecondary dark:text-dark-cTextSecondary tracking-wide leading-relaxed max-w-xs">
        Es importante que tengas un logo definido dentro de Xervix
      </p>
    </div>
  </div>
);

const DocumentUploader: React.FC<{
  documents: { [key: string]: File | null };
  uploading: { [key: string]: number };
  businessType: string;
  onDocumentChange: (e: React.ChangeEvent<HTMLInputElement>, key: string) => void;
  onRemoveDocument: (key: string) => void;
}> = ({ documents, uploading, businessType, onDocumentChange, onRemoveDocument }) => {
  const documentConfig = getDocumentConfig(businessType);

  return (
    <div className="p-6 bg-white">
      {documentConfig.map(({ key, label }) => (
        <DocumentItem
          key={key}
          documentKey={key}
          label={label}
          document={documents[key]}
          uploadProgress={uploading[key]}
          onDocumentChange={onDocumentChange}
          onRemoveDocument={onRemoveDocument}
        />
      ))}
    </div>
  );
};

const DocumentItem: React.FC<{
  documentKey: string;
  label: string;
  document: File | null;
  uploadProgress: number;
  onDocumentChange: (e: React.ChangeEvent<HTMLInputElement>, key: string) => void;
  onRemoveDocument: (key: string) => void;
}> = ({ documentKey, label, document, uploadProgress, onDocumentChange, onRemoveDocument }) => (
  <div key={documentKey} className="mb-4">
    <label className="block mb-2 font-medium text-gray-700">
      {label}
    </label>
    <div className="flex flex-col items-center justify-center cursor-pointer border border-dashed border-gray-300 rounded-lg p-3 hover:border-blue-500 hover:bg-blue-50">
      {uploadProgress > 0 && uploadProgress < 100 ? (
        <FileUploadProgress
          progress={uploadProgress}
          fileName={document?.name || "Cargando archivo..."}
          onCancel={() => onRemoveDocument(documentKey)}
        />
      ) : document ? (
        <div className="flex items-center">
          <DocumentTextIcon className="w-6 h-6 text-cTextSecondary" />
          <div className="ml-2 flex flex-row items-center gap-2">
            <span className="block text-sm font-medium text-gray-800">
              {document.name}
            </span>
            <span className="block text-xs text-gray-500">
              {(document.size / (1024 * 1024)).toFixed(2)} MB
            </span>
          </div>
          <div className="flex ml-auto gap-2">
            <button
              className="text-blue-500 hover:text-blue-700"
              onClick={() => alert(`Descargando ${document.name}`)}
            >
              <ArrowDownTrayIcon className="w-5 h-5" />
            </button>
            <button
              className="text-red-500 hover:text-red-700"
              onClick={() => onRemoveDocument(documentKey)}
            >
              <TrashIcon className="w-5 h-5" />
            </button>
          </div>
        </div>
      ) : (
        <label
          htmlFor={documentKey}
          className="flex flex-row gap-2 items-center justify-center"
        >
          <span className="text-gray-500 text-sm">Subir documento</span>
          <ArrowUpTrayIcon className="w-8 h-8 mb-2 text-gray-400" />
          <input
            id={documentKey}
            type="file"
            className="hidden"
            onChange={(e) => onDocumentChange(e, documentKey)}
          />
        </label>
      )}
    </div>
  </div>
);

// Main Component (Organism)
const BusinessOnboardingPage: React.FC<{ onNext: () => void }> = ({ onNext }) => {
  const { t } = useTranslation();
  const {
    businessTypes,
    fetchBusinessTypes,
    upsertBusinessInfo,
    uploadDocuments,
    isLoading,
  } = useOnboardingStore();

  const [logo, setLogo] = useState<File | null>(null);
  const [businessType, setBusinessType] = useState("");
  const [formData, setFormData] = useState<BusinessFormData>({
    firstName: "",
    lastName: "",
    identification: "",
    phoneNumber: "",
    description: "",
  });
  const [error, setError] = useState<string | null>(null);
  const [documents, setDocuments] = useState<{ [key: string]: File | null }>({
    cedulaRepresentante: null,
    camaraComercio: null,
    certificadoBancario: null,
    rut: null,
  });
  const [businessDescription, setBusinessDescription] = useState<string>("");
  const [uploading, setUploading] = useState<{ [key: string]: number }>({
    cedulaRepresentante: 0,
    camaraComercio: 0,
    certificadoBancario: 0,
    rut: 0,
  });
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [showDocsModal, setShowDocsModal] = useState(false);
  const [missingDocs, setMissingDocs] = useState<string[]>([]);
  const [uploadedDocs, setUploadedDocs] = useState<string[]>([]);
  const [hasErrorPhone, setHasErrorPhone] = useState<Boolean>(false);
  const { openSpinner } = useSpinnerStore();

  useEffect(() => {
    if (isLoading) return openSpinner(true);
    else openSpinner(false);
  }, [isLoading, openSpinner])

  // Fetch business types when the component is mounted
  useEffect(() => {
    fetchBusinessTypes();
  }, [fetchBusinessTypes]);

  // Add helper function
  const checkDocuments = (businessType: string): boolean => {
    if (!businessType) return false;

    const requiredDocs = getDocumentConfig(businessType);
    const missing: string[] = [];
    const uploaded: string[] = [];

    requiredDocs.forEach(({ key, label }) => {
      if (!documents[key]) {
        missing.push(label);
      } else {
        uploaded.push(label);
      }
    });

    setMissingDocs(missing);
    setUploadedDocs(uploaded);
    return missing.length === 0;
  };

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Validate file type
    if (!file.type.startsWith("image/")) {
      setError(t("onboarding.business.invalidImageType"));
      return;
    }

    // Validate file size (e.g., 5MB limit)
    const MAX_SIZE = 5 * 1024 * 1024; // 5MB
    if (file.size > MAX_SIZE) {
      setError(t("onboarding.business.fileTooLarge"));
      return;
    }

    setLogo(file);
    setError(null);
  };

  const handleDocumentChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (!validateDocument(file, key)) return;

    try {
      // Simulate upload progress
      const interval = setInterval(() => {
        setUploading((prev) => {
          const progress = prev[key] + 10;
          if (progress >= 100) {
            clearInterval(interval);
          }
          return { ...prev, [key]: Math.min(progress, 100) };
        });
      }, 200);

      // Update state with new file
      setDocuments((prev) => ({ ...prev, [key]: file }));
    } catch (error) {
      setError(t("onboarding.business.errorUploadingDocument"));
    } finally {
      // Simulate upload progress
      const interval = setInterval(() => {
        setUploading((prev) => {
          const progress = prev[key] + 10;
          if (progress >= 100) {
            clearInterval(interval);
          }
          return { ...prev, [key]: Math.min(progress, 100) };
        });
      }, 200);
    }
  };

  const isValidFileType = (file: File, types: string[]) => {
    return types.some((type) => file.type.startsWith(type));
  };

  const validateDocument = (file: File, key: string): boolean => {
    const MAX_SIZE = 10 * 1024 * 1024; // 10MB

    if (file.size > MAX_SIZE) {
      setError(t("documents.fileTooLarge", { size: "10" }));
      return false;
    }

    // Validate file types based on document type
    const validTypes =
      key === "logo"
        ? ["image/"]
        : [
          "application/pdf",
          "image/",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ];

    if (!isValidFileType(file, validTypes)) {
      setError(t("documents.invalidFileType"));
      return false;
    }
    return true;
  };

  useEffect(() => {
    fetchBusinessTypes();
    // Cleanup function
    return () => {
      // Revoke any object URLs created
      if (logo) URL.revokeObjectURL(URL.createObjectURL(logo));
      Object.values(documents).forEach((doc) => {
        if (doc) URL.revokeObjectURL(URL.createObjectURL(doc));
      });
    };
  }, [fetchBusinessTypes]);

  /* const getFileIcon = (fileName: string) => {
    const extension = fileName.split(".").pop()?.toLowerCase();
    if (extension === "pdf")
      return <DocumentTextIcon className="w-6 h-6 text-red-500" />;
    if (extension === "xls" || extension === "xlsx")
      return <DocumentTextIcon className="w-6 h-6 text-green-500" />;
    return <DocumentTextIcon className="w-6 h-6 text-cTextSecondary" />;
  }; */

  // Manejo del formulario cuando el usuario lo envía
  const handleSubmit = async () => {
    openSpinner(true);
    setError(null);
    const allDocsUploaded = checkDocuments(businessType);
    if (!allDocsUploaded) {
      setShowDocsModal(true);
      openSpinner(false);
      return;
    }

    try {
      // Primero actualizar la información del negocio
      await upsertBusinessInfo({
        businessType,
        name:
          businessType === BUSINESS_TYPES.COMPANY
            ? (formData as CompanyForm).companyName
            : (formData as NaturalPersonForm).firstName +
            " " +
            (formData as NaturalPersonForm).lastName,
        identification:
          businessType === BUSINESS_TYPES.COMPANY
            ? (formData as CompanyForm).nit
            : (formData as NaturalPersonForm).identification,
        phoneNumber: formData.phoneNumber,
        businessDescription,
      });

      // Preparar los documentos para subir
      const documentFiles: File[] = [];
      const documentTypes: number[] = [];

      // Si hay logo, agregarlo primero
      if (logo) {
        documentFiles.push(logo);
        documentTypes.push(DOCUMENT_TYPES.LOGO);
      }

      // Agregar los demás documentos
      getDocumentConfig(businessType).forEach(({ key, type }) => {
        const doc = documents[key];
        if (doc) {
          documentFiles.push(doc);
          documentTypes.push(type);
        }
      });

      // Subir documentos si hay alguno
      if (documentFiles.length > 0) await uploadDocuments(undefined, documentFiles, documentTypes);
      onNext();
      openSpinner(false);
    } catch (err) {
      openSpinner(false);
      setError(t("onboarding.business.errorOnSubmit"));
      toast.error(t("onboarding.business.errorOnSubmit"));
    }
  };

  const proceedWithoutDocs = async () => {
    openSpinner(true);
    try {
      await upsertBusinessInfo({
        businessType,
        name:
          businessType === BUSINESS_TYPES.COMPANY
            ? (formData as CompanyForm).companyName
            : (formData as NaturalPersonForm).firstName +
            " " +
            (formData as NaturalPersonForm).lastName,
        identification:
          businessType === BUSINESS_TYPES.COMPANY
            ? (formData as CompanyForm).nit
            : (formData as NaturalPersonForm).identification,
        phoneNumber: formData.phoneNumber,
        businessDescription,
      });

      // Upload logo if exists
      if (logo) await uploadDocuments(undefined, [logo], [DOCUMENT_TYPES.LOGO]);

      // Upload any documents that were provided
      const availableDocuments = Object.entries(documents)
        .filter(([_, file]) => file !== null)
        .map(([key, file]) => ({
          file,
          type: getDocumentConfig(businessType).find((doc) => doc.key === key)
            ?.type,
        }))
        .filter((item) => item.file && item.type);

      if (availableDocuments.length > 0) {
        await uploadDocuments(
          undefined,
          availableDocuments.map((d) => d.file!) as File[],
          availableDocuments.map((d) => d.type!) as number[]
        );
      }

      setShowDocsModal(false);
      onNext();
      openSpinner(false);
    } catch (err) {
      openSpinner(false);
      setError(t("onboarding.business.errorOnSubmit"));
      toast.error(t("onboarding.business.errorOnSubmit"));
    }
  };

  const handleInputChange = (field: string, value: string) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handlePhoneChange = (field: string, value: string) => {
    if (!value) return setHasErrorPhone(false);
    const isValid: boolean = isValidPhoneNumber(value);
    setHasErrorPhone(!isValid);
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleRemoveDocument = (key: string) => {
    setDocuments((prev) => ({ ...prev, [key]: null }));
    setUploading((prev) => ({ ...prev, [key]: 0 }));
  };

  /* const getFileSizeInMB = (size: number) => (size / (1024 * 1024)).toFixed(2); */

  return (
    <>
      <div className="p-8 bg-white flex items-center justify-center">
        <div className="w-full mx-auto text-center">
          {/* <h1 className="text-3xl font-bold mb-6">
          {t('onboarding.business.title')}
        </h1> */}

          {/* Muestra el error si existe */}
          {error && <div className="text-red-500 mb-4">{error}</div>}

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Columna izquierda */}
            <div>
              <LogoUploader
                logo={logo}
                onLogoChange={handleLogoChange}
                fileInputRef={fileInputRef}
              />
              {/* Tipo de sociedad */}
              <div className="mb-6">
                <label
                  htmlFor="businessType"
                  className="block text-left mb-2 line-clamp-3"
                >
                  {t("onboarding.business.businessType")}
                </label>
                <select
                  id="businessType"
                  value={businessType}
                  onChange={(e) => {
                    setBusinessType(e.target.value);
                    setFormData(
                      e.target.value === BUSINESS_TYPES.NATURAL
                        ? {
                          firstName: "",
                          lastName: "",
                          identification: "",
                          phoneNumber: "",
                          description: "",
                        }
                        : {
                          companyName: "",
                          nit: "",
                          phoneNumber: "",
                          description: "",
                        }
                    );
                  }}
                  className="w-full px-4 py-2 border rounded-lg"
                >
                  <option value="">
                    {t("onboarding.business.selectBusinessType")}
                  </option>
                  {businessTypes.map((type) => (
                    <option
                      key={type.businessTypeId}
                      value={type.businessTypeId}
                    >
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>

              {businessType === BUSINESS_TYPES.NATURAL ? (
                <>
                  <InputField
                    label="Nombres"
                    id="firstName"
                    value={(formData as NaturalPersonForm).firstName}
                    onChange={(value) => handleInputChange("firstName", value)}
                  />
                  <InputField
                    label="Apellidos"
                    id="lastName"
                    value={(formData as NaturalPersonForm).lastName}
                    onChange={(value) => handleInputChange("lastName", value)}
                  />
                </>
              ) : businessType === BUSINESS_TYPES.COMPANY ? (
                <InputField
                  label="Nombre de la empresa"
                  id="companyName"
                  value={(formData as CompanyForm).companyName}
                  onChange={(value) => handleInputChange("companyName", value)}
                />
              ) : null}

              <InputField
                label={
                  businessType === BUSINESS_TYPES.COMPANY
                    ? "NIT"
                    : "Número de identificación"
                }
                id="identification"
                value={
                  businessType === BUSINESS_TYPES.COMPANY
                    ? (formData as CompanyForm).nit
                    : (formData as NaturalPersonForm).identification
                }
                onChange={(value) =>
                  handleInputChange(
                    businessType === BUSINESS_TYPES.COMPANY
                      ? "nit"
                      : "identification",
                    value
                  )
                }
              />

              {/* Número de teléfono con indicativo */}
              <div className="mb-6">
                <label htmlFor="phoneNumber" className="block text-left mb-2">
                  {t("onboarding.business.phoneNumber")}
                </label>
                <PhoneInput
                  international
                  limitMaxLength={true}
                  defaultCountry="CO" // País por defecto
                  countryCallingCodeEditable={false}
                  value={formData.phoneNumber}
                  onChange={(e) => handlePhoneChange("phoneNumber", e as string)} // Ajusta el valor del teléfono
                  className="w-full px-4 py-2 border rounded-lg"
                />
                {hasErrorPhone && formData.phoneNumber &&
                  <span className="w-full flex justify-end mt-1 pr-2 text-xs text-[#F10202]">
                    {t("onboarding.business.invalidFormat")}
                  </span>
                }
              </div>
            </div>

            {/* second derecha */}
            <div>
              <TextAreaField
                label={businessType === BUSINESS_TYPES.NATURAL ? "Cuéntanos un poco de ti" : "Cuéntanos un poco de la empresa"}
                id="description"
                value={formData.description}
                onChange={(value) => handleInputChange("description", value)}
                placeholder={t("onboarding.business.businessDescriptionPlaceholder")}
              />
            </div>
            {/* Third Column */}
            {businessType && (
              <DocumentUploader
                documents={documents}
                uploading={uploading}
                businessType={businessType}
                onDocumentChange={handleDocumentChange}
                onRemoveDocument={handleRemoveDocument}
              />
            )}

            {/* Botón de continuar */}
            <div className="mt-8">
              <button
                onClick={handleSubmit}
                disabled={isLoading}
                className={`px-8 py-3 rounded-lg ${!businessType ||
                  !formData.phoneNumber ||
                  !formData.description
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-red-500 text-white"
                  }`}
              >
                {t("onboarding.business.continue")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showDocsModal}
        onClose={() => setShowDocsModal(false)}
        title="Documentos Pendientes"
      >
        <div className="p-4 max-w-lg">
          <p className="mb-4 leading-relaxed">
            Puedes continuar con el registro, pero ten en cuenta que los
            siguientes documentos son necesarios para completar tu perfil:
          </p>

          {uploadedDocs.length > 0 && (
            <>
              <p className="font-semibold text-green-600 mt-4">
                Documentos subidos:
              </p>
              <ul className="list-disc pl-5 mt-2">
                {uploadedDocs.map((doc) => (
                  <li key={doc} className="text-green-600">
                    {doc}
                  </li>
                ))}
              </ul>
            </>
          )}

          {missingDocs.length > 0 && (
            <>
              <p className="font-semibold text-red-600 mt-4">
                Documentos pendientes:
              </p>
              <ul className="list-disc pl-5 mt-2">
                {missingDocs.map((doc) => (
                  <li key={doc} className="text-red-600">
                    {doc}
                  </li>
                ))}
              </ul>
            </>
          )}

          <div className="flex justify-center items-center gap-4 mt-6">
            <button
              onClick={() => setShowDocsModal(false)}
              className="px-4 py-2 border rounded-lg hover:bg-gray-50"
            >
              Subir documentos
            </button>
            <button
              onClick={proceedWithoutDocs}
              className="px-4 py-2 bg-bgBrand text-white rounded-lg hover:bg-bgBrandHover"
            >
              Continuar con el registro
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BusinessOnboardingPage;
