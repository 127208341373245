
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useServiceStore from "@/store/useServiceRequestStore";
import useProposalStore from "@/store/useProposalStore";
import { ServiceRequest } from '@/types/request.types';


interface RequestsSectionProps {
  onSelectRequest: (request: any) => void;
}

const RequestsSection: React.FC<RequestsSectionProps> = ({ onSelectRequest }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { response, getRequestsByUser } = useServiceStore();
  const {  data, proposalCounts, getProposalCount, fetchProposalsByRequest } = useProposalStore();
  const [selectedRequest, setSelectedRequest] = useState<string | null>(null);
  console.log(data, proposalCounts);
  

  const handleServiceButtonClick = () => {
    navigate('/categories');
  }

  useEffect(() => {
    getRequestsByUser();
  }, [getRequestsByUser]);

  const handleRequestSelect = async (request: ServiceRequest) => {
    setSelectedRequest(request.serviceRequestId);
    await fetchProposalsByRequest(request.serviceRequestId);
    onSelectRequest(request);
  };

  // Load proposals for all requests
  useEffect(() => {
    if (response?.items) {
      response.items.forEach((request: ServiceRequest) => {
        getProposalCount(request.serviceRequestId);
      });
    }
  }, [response?.items]);

  useEffect(() => {
    if (selectedRequest) {
      fetchProposalsByRequest(selectedRequest);
    }
  }, [selectedRequest, fetchProposalsByRequest]);

  return (
    <section className="p-6">
      <h2 className="text-lg font-medium text-center mb-6">Mis Solicitudes</h2>
      {(!response?.items || response?.items.length === 0) ? (
        <div className="text-center">
          <img src="assets/icons/brand/no-requests.png" alt="No requests" className="mx-auto mb-4 w-48" />
          <h3 className="text-xl font-semibold mb-2">{t('requestsAndProposals.noRequests.title')}</h3>
          <p className="text-gray-600">{t('requestsAndProposals.noRequests.description')}</p>
        </div>
      ) : (
        <div className="grid gap-4">
          {response?.items.map((request: ServiceRequest) => (
            <div
            key={request.serviceRequestId}
            className={`pr-6 pl-4 py-4 flex items-center gap-4 rounded-lg transition-all cursor-pointer ${
              selectedRequest === request.serviceRequestId
                ? 'border-2 border-red-400 shadow-md'
                : 'shadow-lg bg-white'
            }`}
            onClick={() => {
              handleRequestSelect(request);
            }}
          >
            <img
              src={request.category.urlImage}
              alt={request.category.name}
              className="size-32 rounded-lg object-cover"
            />
            <div className="flex-grow">
              <div className="flex justify-between items-center">
                <h1 className="font-medium text-xl text-cText dark:text-dark-cText">{request.category.name}</h1>
                <p className="text-cTextSecondary dark:text-dark-cTextSecondary text-lg">{proposalCounts[request.serviceRequestId] || 0} Propuestas</p>
              </div>
              <div className="flex justify-between items-center mt-2">
                <div className='flex flex-col gap-2'>
                  <p className="text-cTextSecondary dark:text-dark-cTextSecondary">{request.services[0]?.name}</p>
                  <p className="text-cTextSecondary dark:text-dark-cTextSecondary">{request.serviceRequestNumber}</p>
                </div>
                <p className="text-cTextSecondary dark:text-dark-cTextSecondary">{new Date(request.requestDate).toLocaleDateString()}</p>
              </div>
            </div>
          </div>
          ))}
        </div>
      )}

    <div className="mt-8 border border-gray-300 rounded-lg p-6">
      <p className="text-cTextSecondary text-center">
        {t('requestsAndProposals.infoBox.text')}
      </p>
      <div className="flex justify-center">
        <button className="mt-4 bg-bgBrand text-white px-6 py-2 rounded-lg hover:bg-bgBrandHover transition-colors"
        onClick = {handleServiceButtonClick}>
          {t('requestsAndProposals.infoBox.button')}
        </button>
      </div>
    </div>
    </section>
  );
};

export default RequestsSection;