import { create } from 'zustand';
import { UserInfo, ProfessionalInfo } from '@/types/profile.types';
import profileService from '@/services/profile.service';


interface ProfileStoreState {
  user: UserInfo | null;
  professional: ProfessionalInfo | null;
  isLoading: boolean;
  error: string | null;
  getProfessionalInfo: (professionalId: string) => Promise<void>;
  getUserTypeInfo: (userType: string) => Promise<void>;
  getProfessionalTypeInfo: (userType?: string) => Promise<void>;
}

const useProfileStore = create<ProfileStoreState>((set, get) => ({
  user: null,
  professional: null,
  isLoading: false,
  error: null,

  getProfessionalInfo: async (professionalId: string) => {
    set({ isLoading: true });
    try {
      const professional = await profileService.fetchProfessionalInfo(professionalId);
      set({ professional, isLoading: false });
    } catch (error) {
      set({ error: 'Error fetching professional info', isLoading: false });
    }
  },
  getUserTypeInfo: async (/* userType: string */) => {
    set({ isLoading: true });
    try {
      const user = await profileService.fetchUserTypeInfo(/* userType */);
      // set({ user, isLoading: false });
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({ error: error.message, isLoading: false });
      } else {
        set({ error: 'An unknown error occurred', isLoading: false });
      }
    }
  },
  getProfessionalTypeInfo: async (/* userType?: string */) => {
    set({ isLoading: true });
    try {
      const professional = await profileService.fetchProfessionalTypeInfo(/* userType */);
      set({ professional, isLoading: false });
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({ error: error.message, isLoading: false });
      } else {
        set({ error: 'An unknown error occurred', isLoading: false });
      }
    }
  },
}));

export default useProfileStore;