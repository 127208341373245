import { create } from 'zustand';
import proposalService from '@/services/proposal.service';
import { ProposalDetail, ProposalResponse, ProposalState } from '@/types/proposal.types';


interface ProposalStore {
  data: ProposalResponse | null;
  proposal: ProposalDetail | null;
  proposalCounts: { [key: string]: number };
  isLoading: boolean;
  error: string | null;
  fetchProposalDetail: (proposalId: string) => Promise<void>;
  updateStatus: (proposalId: string, status: ProposalState) => Promise<void>;
  getProposalCount: (serviceRequestId: string) => Promise<void>;
  fetchProposalsByRequest: (serviceRequestId: string) => Promise<void>;
  submitProposal: (data: {
    serviceRequestId: string;
    proposalValue: number;
    daysToComplete: number;
    descriptionProposet: string;
  }) => Promise<void>;
}

const useProposalStore = create<ProposalStore>((set) => ({
  data: null,
  proposal: null,
  proposalCounts: {},
  isLoading: false,
  error: null,
  fetchProposalDetail: async (proposalId: string) => {
    set({ isLoading: true, error: null });
    try {
      const proposal = await proposalService.getProposalDetail(proposalId);
      set({ proposal, isLoading: false });
    } catch (error) {
      set({
        isLoading: false,
        error: error instanceof Error ? error.message : 'Error fetching proposal detail'
      });
    }
  },
  fetchProposalsByRequest: async (serviceRequestId: string) => {
    try {
      const response = await proposalService.getProposalsByServiceRequest(serviceRequestId);
      set((state) => ({
        data: response,
        proposalCounts: {
          ...state.proposalCounts,
          [serviceRequestId]: response.totalItems
        }
      }));
    } catch (error) {
      console.error('Error:', error);
    }
  },
  updateStatus: async (proposalId: string, status: ProposalState) => {
    set({ isLoading: true, error: null });
    try {
      await proposalService.updateProposalStatus(proposalId, status);
      set({ isLoading: false });
    } catch (error) {
      set({
        isLoading: false,
        error: error instanceof Error ? error.message : 'Error updating proposal status'
      });
    }
  },
  getProposalCount: async (serviceRequestId: string) => {
    try {
      const response = await proposalService.getProposalsByServiceRequest(serviceRequestId);
      set((state) => ({
        proposalCounts: {
          ...state.proposalCounts,
          [serviceRequestId]: response.totalItems
        }
      }));
    } catch (error) {
      console.error('Error:', error);
    }
  },
  submitProposal: async (data) => {
    set({ isLoading: true, error: null });
    try {
      await proposalService.addProposal(data);
      set({ isLoading: false });
    } catch (error) {
      set({
        isLoading: false,
        error: error instanceof Error ? error.message : 'Error submitting proposal'
      });
    }
  }
}));

export default useProposalStore;