import React from "react";
import UserRoleNavigation from "@/components/molecules/UserRoleNavigation";

export const HeaderDashboard: React.FC = () => {
    return (
      <header className="pl-4 pr-8 py-2.5 flex justify-between bg-gray-100 items-center">
        <div className="flex items-center bg-green-500 text-white px-4 py-2 rounded-md">
          CUENTA ACTIVA
        </div>
        <UserRoleNavigation />
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2">
            <span>Maria Fernanda Uribe</span>
            <img
              src="/placeholder.svg?height=32&width=32"
              alt="Profile"
              className="w-8 h-8 rounded-full"
            />
          </div>
        </div>
      </header>
    );
  };