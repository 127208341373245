import { create } from 'zustand';
import evaluationService from '@/services/evaluation.service';
import { EvaluationState } from '@/types/evaluation.types';

export const useServiceRequestEvaluation = create<EvaluationState>((set) => ({
    isLoading: false,
    error: null,
    submitEvaluation: async (data) => {
        set({ isLoading: true, error: null });
        try {
            await evaluationService.submitEvaluation(data);
            set({ isLoading: false });
        } catch (error) {
            set({ isLoading: false, error: error instanceof Error ? error.message : 'Unknown error' });
        }
    },
}));