import { create } from "zustand";

interface SpinnerState {
    open: boolean;
    openSpinner: (value: boolean) => void;
}

export const useSpinnerStore = create<SpinnerState>()((set) => ({
    open: false,
    openSpinner: (value: boolean) => set(() => ({ open: value })),
}));
