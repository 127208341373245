import React from "react";

export const NoServiceMessage: React.FC<{
  title?: string;
  messageOne?: string;
  messageTwo?: string;
  buttonText?: string;
  onClick: () => void;
}> = ({ title, messageOne, messageTwo, buttonText, onClick }) => (
  <div className="flex flex-col items-center justify-center text-center p-8 bg-cBackground rounded-2xl shadow-md gap-4">
    <h3 className="font-bold text-lg mb-2">{title}</h3>
    <p className="text-cTextSecondary dark:text-dark-cText text-sm">{messageOne}</p>
    <p className="text-cTextSecondary text-sm">{messageTwo}</p>
    <button
      onClick={onClick}
      className="mt-4 bg-bgBrand text-textOnBrand py-2 px-6 rounded-lg hover:bg-bgBrandHover transition"
    >
      {buttonText}
    </button>
  </div>
);
