import { xervixApi } from '@/config/axios.config';
import { API_ROUTES } from '@/constants/apiRoutes';
import { CreatePostRequest, Post, PostResponse } from '@/types/post.types';

const createPost = async (data: CreatePostRequest): Promise<Post> => {
  const response = await xervixApi.post(API_ROUTES.POST.CREATE_POST, data);
  return response.data;
};

const getPostsByProfessional = async (professionalId: string, isActive?: boolean): Promise<Post[]> => {
  const response = await xervixApi.get(
    API_ROUTES.POST.GET_BY_PROFESSIONAL(professionalId),
    {
      params: { isActive }
    }
  );
  return response.data;
};

const getPostById = async (postId: string): Promise<Post> => {
  const response = await xervixApi.get(API_ROUTES.POST.GET_BY_ID(postId));
  return response.data;
};

const deletePost = async (postId: string): Promise<void> => {
  await xervixApi.delete(API_ROUTES.POST.DELETE_POST(postId));
};

export default {
  createPost,
  getPostsByProfessional,
  getPostById,
  deletePost,
};