import { create } from 'zustand';
import statisticService from '@/services/statistic.service';
import { Statistic } from '@/types/statistic.types';

interface StatisticState {
  statistics: Statistic | null;
  fetchStatistics: () => Promise<void>;
}

const useStatisticStore = create<StatisticState>((set) => ({
  statistics: null,
  fetchStatistics: async () => {
    const statistics = await statisticService.getStatistics();
    set({ statistics });
  },
}));

export default useStatisticStore;