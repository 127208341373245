import React, { useEffect, useMemo } from "react";
import useCategoryStore from "../../../store/useCategoryStore";
import { CategoryHierarchy } from "../../../types/category.types";
import { CategoryCard } from "../../molecules/CategoryCard";
import { useNavigate } from "react-router-dom";
import routesConfig from "../../../config/routes.config";
import CardSkeleton from "../../../components/atoms/CardSkeleton";

const CategoriesGrid: React.FC = () => {
  const navigate = useNavigate();
  const { categoryHierarchy, fetchCategoriesHierarchy, isLoading } =
    useCategoryStore();

  useEffect(() => {
    fetchCategoriesHierarchy();
  }, [fetchCategoriesHierarchy]);

  const calculateTotalServices = useMemo(
    () => (category: CategoryHierarchy) =>
      category.subCategories.reduce((acc, sub) => acc + sub.services.length, 0),
    []
  );

  const handleCardClick = (category: CategoryHierarchy) => {
    navigate(
      routesConfig.categoryServicesPage.replace(":id", category.categoryId),
      {
        state: { category },
      }
    );
  };

  if (isLoading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-2">
        {[...Array(10)].map((_, index) => (
          <CardSkeleton key={index} />
        ))}
      </div>
    );
  }

  if (!categoryHierarchy?.length) {
    return (
      <div className="text-center py-8 text-gray-500 dark:text-gray-400">
        No categories available
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-4">
      {categoryHierarchy &&
        categoryHierarchy.map((category: CategoryHierarchy, index: number) => (
          <CategoryCard
            key={category.categoryId}
            category={category}
            isNavigable={true}
            totalServices={calculateTotalServices(category)}
            handleClick={handleCardClick}
            loading={false}
          />
        ))}
    </div>
  );
};

export default CategoriesGrid;
